import React from 'react';
import Section from '../../../components/section/Section';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import { raceOverSea } from '../../../staticData/videoLinks';
import SectionTitle from '../../../components/section/SectionTitle';
import IntroBox from '../../../components/box/IntroBox';
import { paths } from '../../../routes/paths';
import IntroBg from '../../../images/raceGame/RaceIntroBg.jpg';
import IntroBgSm from '../../../images/raceGame/RaceIntroBg-sm.jpg';

const WorldIntro = () => {
  return (
    <Section id="intro" fullHeight fullContainerHeight video={raceOverSea} bgImg={IntroBg} bgImgSm={IntroBgSm}>
      <SectionTitle decorated={false}>Race Game</SectionTitle>
      <IntroBox transparent>
        The first 3D game experience in the Angelic World is the Angelic Race Game – a full 3D competitive racing game
        created in Unreal Engine 5. It is located on a specific Angelic Island thoroughly chosen and prepared for
        angelic flying Tournaments by the highest Angels. Here the players, as the angels in training, test their
        acrobatic and flying skills, trying to finish the race track as fast as possible, avoiding various obstacles and
        island traps, using bonus boosts, and enjoying the fascinating, exotic view of the tropical oceanic athmosphere.
        The game is in development stage.
        <SectionSwitcher smallRelative to={`${paths.game}/#development`} text="" />
      </IntroBox>
      <SectionSwitcher to={`${paths.game}/#development`} />
    </Section>
  );
};

export default WorldIntro;
