// Reusable values
const colorWhite = '#ffffff';
const colorBlack = '#000000';

const darkGreen = '#04232A';
const lightGreen = '#65DBC5';
const gold = '#DDCF9F';
const gold2 = '#9e7e3b';
const lightPaleGold = '#F7F1D5';
const darkPaleGold = '#D2C088';
const darkerPaleGold = '#6a530c';
const blueCrystal = '#00FFFF';
const tyrque = '#7febff';

const paddingBasic = 20;

const fontFamilyMainHeading = "'Cinzel-Bold', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

const theme = {
  color: {
    primaryDark: '#041c2a',
    primaryLight: '#6eddf9',
    secondaryDark: darkPaleGold,
    secondaryLight: lightPaleGold,
    defaultTitle: colorWhite,
    basicBoxTitle: lightPaleGold,
    defaultParagraph: colorWhite,
    defaultBg: colorBlack,
    mobileMenuBackground: colorWhite,
    menuItem: lightGreen,
    mobileMenuItem: darkPaleGold,
    readMoreButton: colorWhite,
    footerLink: gold2,
    footerLinkHover: 'rgba(1, 24, 37, 0.9)',
    footerLinkHoverShadow: '#c8dcea',
    subItem: '#e8d488',
    gold,
    lightSwitcher: colorWhite,
    darkSwitcher: '#041c2a',
    emptyBg: darkGreen,
    footer: {
      border: colorWhite,
      background: colorWhite
    },
    team: {
      imgBorder: darkPaleGold,
      name: colorWhite,
      role: colorWhite,
      description: colorWhite
    }
  },
  font: {
    size: {
      defaultText: '14px',
      contactLink: '22px',
      smallMobile: '10px',
      menuItem: '28px',
      menuSubItem: '16px',
      styledHashLink: '20px',
      title1: '44px',
      title2: '38px',
      title3: '30px',
      title4: '20px',
      title5: '14px',
      title6: '12px',
      buttons: {
        basic: '18px',
        learnMore: '20px'
      },
      team: {
        nameSmall: '24px',
        name: '28px',
        roleSmall: '22px',
        role: '22px',
        descriptionSmall: '16px',
        description: '18px'
      },
      story: {
        nameMobile: '48px',
        name: '62px',
        roleMobile: '30px',
        role: '22px'
      }
    },
    lineHeight: {
      heading: '102px',
      text: '35px',
      contactLink: '40px'
    },
    family: {
      defaultFamily: "'Maven_Pro', Sans-serif",
      buttons: "'Cinzel-Bold', Sans-serif",
      titles1: "'Cinzel-Bold', Sans-serif",
      titles2: "'Cinzel-Bold', Sans-serif",
      titles3: "'Cinzel-Bold', Sans-serif",
      titles4: "'Cinzel-Bold', Sans-serif",
      titles5: "'Cinzel-Regular', Sans-serif",
      titles6: "'Cinzel-Regular', Sans-serif",
      paragraphs: "'Maven_Pro', Sans-serif",
      mainHeading: fontFamilyMainHeading,
      teamRole: fontFamilyMainHeading
    }
  },
  padding: {
    tiny: `${paddingBasic / 4}px`,
    half: `${paddingBasic / 2}px`,
    basic: `${paddingBasic}px`,
    double: `${paddingBasic * 2}px`,
    triple: `${paddingBasic * 3}px`,
    quadruple: `${paddingBasic * 4}px`
  },
  dimensions: {
    maxWidth: '1200px',
    maxWidthUltra: '1800px'
  },
  borderRadius: '5px',
  easing: {
    basic: 'Expo.out(1)'
  },
  breakpoints: {
    xs: '375px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
    xxxl: '1800px',
    showVideos: 992,
    activateScenes: 992,
    showMobileMenu: '992px'
  }
};

export default theme;
