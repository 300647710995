import React from 'react';
import SectionTitle from '../../components/section/SectionTitle';
import Section from '../../components/section/Section';
import ImageBox from '../../components/box/ImageBox';
import Row from '../../components/grid/Row';
import Col from '../../components/grid/Col';
import SectionSwitcher from '../../components/sectionSwither/SectionSwitcher';

const CardsSection = ({ id, linkTo, linkText, boxes, title, bgImg, bgImgSm }) => {
  return (
    <Section fullHeight bg="gray" bgImg={bgImg} bgImgSm={bgImgSm} id={id} paddingTop="80px">
      <SectionTitle>{title}</SectionTitle>
      <Row lowerBreak>
        {boxes.map((box, index) => (
          <Col key={`box-${index}`}>
            <ImageBox
              title={box.title}
              subTitle={box.subTitle}
              content={box.content}
              img={box.img}
              decorations={box.decorations}
              maxWidth="350px"
            />
          </Col>
        ))}
      </Row>
      <SectionSwitcher to={linkTo} text={linkText} showAlways smallRelative />
    </Section>
  );
};

export default CardsSection;
