import React from 'react';
import bgImg from '../../../images/DefaultGeneralBg.jpg';
import bgImgSm from '../../../images/DefaultGeneralBg-sm.jpg';
import GeneralGallerySection from '../GeneralGallerySection';

function importAll(r) {
  return r.keys().map(r);
}

const AoAIms = importAll(require.context('../../../images/nfts/gallery/aoa/', false, /\.(png|jpe?g|svg)$/));
const AoAThumbs = importAll(require.context('../../../images/nfts/gallery/aoa/thumbs/', false, /\.(png|jpe?g|svg)$/));
const GoAIms = importAll(require.context('../../../images/nfts/gallery/goa/', false, /\.(png|jpe?g|svg)$/));
const GoAThumbs = importAll(require.context('../../../images/nfts/gallery/goa/thumbs/', false, /\.(png|jpe?g|svg)$/));
const CloseUpsIms = importAll(require.context('../../../images/nfts/gallery/closeups/', false, /\.(png|jpe?g|svg)$/));
const CloseUpsThumbs = importAll(
  require.context('../../../images/nfts/gallery/closeups/thumbs/', false, /\.(png|jpe?g|svg)$/)
);
const HighAngelsIms = importAll(
  require.context('../../../images/nfts/gallery/highAngels/', false, /\.(png|jpe?g|svg)$/)
);
const HighAngelsThumbs = importAll(
  require.context('../../../images/nfts/gallery/highAngels/thumbs/', false, /\.(png|jpe?g|svg)$/)
);
const HighGuardiansIms = importAll(
  require.context('../../../images/nfts/gallery/highGuardians/', false, /\.(png|jpe?g|svg)$/)
);
const HighGuardiansThumbs = importAll(
  require.context('../../../images/nfts/gallery/highGuardians/thumbs/', false, /\.(png|jpe?g|svg)$/)
);

const galleryRowHeight = 100;
const galleryImageMargin = 4;

const AoAImages = AoAIms.map((item, index) => ({
  src: item,
  thumbnail: AoAThumbs[index],
  caption: 'Angels of Aether'
}));

const GoAImages = GoAIms.map((item, index) => ({
  src: item,
  thumbnail: GoAThumbs[index],
  caption: 'Guardians of Aether'
}));

const CloseUpsImages = CloseUpsIms.map((item, index) => ({
  src: item,
  thumbnail: CloseUpsThumbs[index],
  caption: 'Angels and Guardians - CloseUps'
}));

const HighAngelsImages = HighAngelsIms.map((item, index) => ({
  src: item,
  thumbnail: HighAngelsThumbs[index],
  caption: 'High Angels of Aether'
}));

const HighGuardiansImages = HighGuardiansIms.map((item, index) => ({
  src: item,
  thumbnail: HighGuardiansThumbs[index],
  caption: 'High Guardians of Aether'
}));

const imageGroups = [
  {
    title: 'Angels',
    images: AoAImages
  },
  {
    title: 'Guardians',
    images: GoAImages
  },
  {
    title: 'Close-ups',
    images: CloseUpsImages
  },
  {
    title: 'High Angels',
    images: HighAngelsImages
  },
  {
    title: 'High Guardians',
    images: HighGuardiansImages
  }
];

const NftsGallery = () => (
  <GeneralGallerySection
    title="NFT Gallery"
    bgImg={bgImg}
    bgImgSm={bgImgSm}
    imageGroups={imageGroups}
    galleryRowHeight={galleryRowHeight}
    galleryImageMargin={galleryImageMargin}
  />
);

export default NftsGallery;
