import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import theme from '../../styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  max-width: 100%;
  max-height: 100%;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: ${(props) => props.sizeLg || props.size}px;
    height: ${(props) => props.sizeLg || props.size}px;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: ${(props) => props.sizeXl || props.sizeLg || props.size}px;
    height: ${(props) => props.sizeXl || props.sizeLg || props.size}px;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.xxxl}) {
    width: ${(props) => props.sizeXXXl || props.sizeXl || props.sizeLg || props.size}px;
    height: ${(props) => props.sizeXXXl || props.sizeXl || props.sizeLg || props.size}px;
  }
`;

const AnimBox = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => (props.image ? `url('${props.image}')` : 'transparent')};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  z-index: ${(props) => props.layer || 1};
`;

const Angel = ({
  particles,
  aura,
  seal,
  wing,
  body,
  leftAccessory,
  fullImage,
  size = 600,
  sizeLg,
  sizeXl,
  sizeXXXl
}) => {
  const springVerticalMovement = useSpring({
    loop: { reverse: true },
    from: { y: 0 },
    config: { duration: 1400 },
    to: { y: 10 }
  });

  const springParticlesBack = useSpring({
    loop: true,
    from: { rotateZ: 45, filter: 'blur(2px)' },
    config: { duration: 20000 },
    to: { rotateZ: -315, filter: 'blur(3px)' }
  });

  const springParticlesFront = useSpring({
    loop: true,
    from: { rotateZ: 0 },
    config: { duration: 18000 },
    to: { rotateZ: 360 }
  });

  const springAura = useSpring({
    loop: { reverse: true },
    from: { rotateZ: 9, filter: 'blur(0px)' },
    config: { duration: 5000 },
    to: { rotateZ: -9, filter: 'blur(1px)' }
  });

  const springSeal = useSpring({
    loop: { reverse: true },
    from: { opacity: 0.2 },
    config: { duration: 3000 },
    to: { opacity: 0.5 }
  });

  const springBody = useSpring({
    loop: { reverse: true },
    from: { transform: 'scaleX(1) skew(0deg, 0deg)' },
    config: { duration: 1400 },
    to: { transform: 'scaleX(1.04) skew(0.7deg, 0.7deg)' }
  });

  const springLeftWing = useSpring({
    loop: { reverse: true },
    from: { rotateZ: 3, transform: 'skew(4deg, 4deg)' },
    config: { duration: 1400 },
    to: { rotateZ: -3, transform: 'skew(0deg, 0deg)' }
  });

  const springRightWing = useSpring({
    loop: { reverse: true },
    from: { rotateZ: -3, transform: 'scaleX(-1) skew(4deg, 4deg)' },
    config: { duration: 1400 },
    to: { rotateZ: 3, transform: 'scaleX(-1) skew(0deg, 0deg)' }
  });

  const springLeftAcc = useSpring({
    loop: { reverse: true },
    from: { opacity: 0.9, transform: 'scaleX(1) scaleY(1) skew(1deg, 1deg)' },
    config: { duration: 2200 },
    to: { opacity: 1, transform: 'scaleX(1.03) scaleY(1.1) skew(-2deg, -2deg)' }
  });

  const isBiggerScreen = useMediaQuery({ query: `(min-width: ${theme.breakpoints.md})` });

  return isBiggerScreen ? (
    <InView>
      {({ inView, ref }) => (
        <Wrapper ref={ref} size={size} sizeLg={sizeLg} sizeXl={sizeXl} sizeXXXl={sizeXXXl}>
          {inView ? (
            <AnimBox layer={1} style={springVerticalMovement}>
              <AnimBox image={aura} layer={2} style={springAura} />
              <AnimBox image={particles} layer={3} style={springParticlesBack} />
              <AnimBox image={particles} layer={3} style={springParticlesFront} />
              {seal && <AnimBox image={seal} layer={4} style={springSeal} />}
              <AnimBox image={wing} layer={5} style={springLeftWing} />
              <AnimBox image={wing} layer={5} style={springRightWing} />
              <AnimBox layer={10} style={springBody}>
                <AnimBox image={body} layer={10} />
              </AnimBox>
              <AnimBox image={leftAccessory} layer={10} style={springLeftAcc} />
            </AnimBox>
          ) : (
            <AnimBox layer={20}>
              <AnimBox image={body} layer={10} />
            </AnimBox>
          )}
        </Wrapper>
      )}
    </InView>
  ) : (
    <Wrapper size={size} sizeLg={sizeLg} sizeXl={sizeXl} sizeXXXl={sizeXXXl}>
      <AnimBox image={fullImage} layer={10} />
    </Wrapper>
  );
};

export default Angel;
