import React from 'react';
import styled, { css } from 'styled-components';
import { getBoxDecorationsByConfig } from '../../helpers/decorations';
import { getGradientStyles } from '../../helpers/gradients';
import Divider from '../divider/Divider';
import bgImg from '../../images/Menu_marble_bar_01.jpg';

export const BoxWrapper = styled.div`
  // if box is attached to the top/bottom of the screen, margin is 0 and background is different
  margin: ${(props) => (props.top || props.bottom ? 0 : props.theme.padding.half)};
  padding: ${(props) => props.theme.padding.basic};
  border: 2px solid ${(props) => props.theme.color.gold};
  background-color: rgba(0, 255, 0, 0.01);
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.color.defaultParagraph};

  ${(props) =>
    props.bottom || props.top
      ? css`
          background-image: url(${bgImg});
          background-position: center;
          background-size: cover;

          p {
            color: ${(props) => props.theme.color.gold};
            font-style: italic;
            font-size: 16px;
            text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
          }
        `
      : getGradientStyles(props.transparent)}

  position: relative;
  min-width: 180px;

  ${(props) =>
    props.fullWidth
      ? css`
          width: 100vw;
        `
      : ''}

  ${(props) =>
    props.fullPercWidth
      ? css`
          width: 100%;
        `
      : ''}

  // fix the box to the top or bottom of the section
  ${(props) =>
    props.top || props.bottom
      ? css`
          position: absolute;
          ${props.top ? 'top: 0;' : 'bottom: 0;'}
          right: 0;
          box-sizing: border-box;
        `
      : ''}

  h4 {
    text-align: left;
    margin: ${(props) => props.theme.padding.half} 0;
  }

  h5 {
    margin: 0;
  }

  a {
    color: cyan;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const BasicWrapper = styled(BoxWrapper)`
  box-sizing: border-box;
  margin-top: ${(props) => props.marginTop || props.theme.padding.half};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const CenteredContent = styled.p`
  width: 100%;
  text-align: center !important;
  margin: 0;
`;

export const BoxTitle = styled.div`
  text-align: left;
  margin: 10px 0;
  font-size: ${(props) => props.theme.font.size.title4};
  font-family: ${(props) => props.theme.font.family.titles4};
  color: ${(props) => props.theme.color.basicBoxTitle};
  text-shadow: 1px 1px 1px #000, 0 2px 10px #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const BoxSubTitle = styled.h5`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const BasicBox = ({
  children,
  title,
  subTitle = null,
  decorations = {},
  transparent = false,
  noDivider = false,
  ...rest
}) => {
  const showDivider = !noDivider && !(!title && !subTitle);
  return (
    <BasicWrapper transparent={transparent} {...rest}>
      {getBoxDecorationsByConfig(decorations)}
      {title && <BoxTitle>{title}</BoxTitle>}
      {subTitle && <BoxSubTitle>{subTitle}</BoxSubTitle>}
      {showDivider && <Divider />}
      {typeof children === 'string' ? <CenteredContent>{children}</CenteredContent> : children}
    </BasicWrapper>
  );
};

export default BasicBox;
