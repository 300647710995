import React from 'react';
import { paths } from './paths';
import HomePage from '../pages/HomePage';
import WorldPage from '../pages/WorldPage';
import NFTsPage from '../pages/NFTsPage';
import GamePage from '../pages/GamePage';
import FAQPage from '../pages/FAQPage';
import PrivacyPage from '../pages/PrivacyPage';
import TermsPage from '../pages/TermsPage';

export const routes = [
  {
    path: paths.home,
    exact: true,
    component: <HomePage />
  },
  {
    path: paths.world,
    exact: true,
    component: <WorldPage />
  },
  {
    path: paths.nfts,
    exact: true,
    component: <NFTsPage />
  },
  {
    path: paths.game,
    exact: true,
    component: <GamePage />
  },
  {
    path: paths.faq,
    exact: true,
    component: <FAQPage />
  },
  {
    path: paths.privacy,
    exact: true,
    component: <PrivacyPage />
  },
  {
    path: paths.terms,
    exact: true,
    component: <TermsPage />
  }
];
