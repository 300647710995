import React from 'react';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  max-width: ${(props) => props.theme.dimensions.maxWidth};

  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.alignBot ? 'flex-end' : 'center')};
  align-items: center;

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
    `}

  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

const Container = ({ children, absolute = false, fullHeight = false, fullWidth = false, alignBot = false }) => {
  return (
    <StyledContainer absolute={absolute} fullHeight={fullHeight} fullWidth={fullWidth} alignBot={alignBot}>
      {children}
    </StyledContainer>
  );
};

export default Container;
