import React from 'react';
import styled from 'styled-components';
import Gallery from 'react-grid-gallery';
import SectionsGroup from '../SectionsGroup';
import BasicBox from '../../../components/box/BasicBox';
import { raceThrougCaves } from '../../../staticData/videoLinks';
import GameDevelopmentBg from '../../../images/raceGame/gameDevelopment/bg/GameDevelopmentBg.jpg';
import GameDevelopmentBgSm from '../../../images/raceGame/gameDevelopment/bg/GameDevelopmentBg-sm.jpg';
import IslandStoryBg from '../../../images/raceGame/RaceIslandStoryBg.jpg';
import IslandStoryBgSm from '../../../images/raceGame/RaceIslandStoryBg-sm.jpg';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import SectionTitle from '../../../components/section/SectionTitle';
import { paths } from '../../../routes/paths';

function importAll(r) {
  return r.keys().map(r);
}

const ImagesArt = importAll(require.context('../../../images/raceGame/gameDevelopment/', false, /\.(png|jpe?g|svg)$/));
const ImagesArtThumbs = importAll(
  require.context('../../../images/raceGame/gameDevelopment/thumbs/', false, /\.(png|jpe?g|svg)$/)
);

const galleryRowHeight = 80;
const galleryImageMargin = 4;

const GalleryArt = ImagesArt.map((item, index) => ({
  src: item,
  thumbnail: ImagesArtThumbs[index],
  thumbnailHeight: galleryRowHeight,
  caption: 'Angels of Aether - Race Game Development'
}));

const GalleryWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  display: block;
  min-height: 120px;

  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;

const infoRows = [
  {
    rightContent: (
      <>
        <BasicBox transparent>
          <SectionTitle decorated={false}>Development</SectionTitle>
          <ul>
            <li>
              The development of a functional game takes an enormous amount of time, resources, energy and effort. We
              strive for high quality and originality, therefore we want to cover all the important areas of the
              creative process – from constructing and programming the game mechanics, through visual concepts and
              design, modeling the 3D assets and environments, to animating and enlivening the characters and scenes in
              Unreal Engine 5; not mentioning all the supporting activities that are necessary.
            </li>
          </ul>
          <GalleryWrapper>
            <Gallery
              images={GalleryArt}
              rowHeight={galleryRowHeight}
              margin={galleryImageMargin}
              enableImageSelection={false}
              preloadNextImage
            />
          </GalleryWrapper>
          <SectionSwitcher smallRelative to={`${paths.game}/#story`} text="" />
        </BasicBox>
      </>
    ),
    id: 'development',
    linkTo: `${paths.game}/#story`,
    linkText: 'Island Story',
    linkDark: true,
    bg: `url(${GameDevelopmentBg})`,
    bgSm: `url(${GameDevelopmentBgSm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    leftContent: (
      <>
        <SectionTitle decorated={false}>Island Story</SectionTitle>
        <BasicBox transparent>
          <ul>
            <li>
              Arcania, the Queen of the Angels of Aether, summoned her specific advisors from among the High Angels to
              help her resolve a matter of training the novice angels. She wanted to find the best place and training
              program for blooming angels, who are eager to serve in the Angelic Legion, thus need to gain the best
              skills to fit the role.
            </li>
            <li>
              After the initial council, she delegated this task to High Angel Ravani, who found a great island suitable
              for the training and built the whole training system.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.game}/#locations`} text="" />
        </BasicBox>
      </>
    ),
    id: 'story',
    linkTo: `${paths.game}/#locations`,
    linkText: 'Important Locations',
    bg: `url(${IslandStoryBg})`,
    bgSm: `url(${IslandStoryBgSm})`,
    bgPosition: 'center',
    video: raceThrougCaves
  }
];

const GameSecions = () => <SectionsGroup id="section" infoRows={infoRows} />;

export default GameSecions;
