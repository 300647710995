import React from 'react';
import styled, { css } from 'styled-components';
import { Tween } from 'react-gsap';
import { Scene } from 'react-scrollmagic';
import Row from '../../components/grid/Row';
import Col from '../../components/grid/Col';
import AboutContainer from '../../components/container/AboutContainer';
import useWindowDimensions from '../../utils/useWindowDimensions';
import getAnimationState from '../../utils/getAnimationState';
import theme from '../../styles/theme';
import SectionSwitcher from '../../components/sectionSwither/SectionSwitcher';

const RowWrapper = styled(Row)`
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: ${(props) => props.bgSm || props.bg || 'transparent'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => props.bgPosition || 'center'} center;
  ${(props) =>
    props.bgStatic
      ? css`
          background-attachment: fixed;

          @supports (-webkit-touch-callout: none) {
            background-attachment: scroll;
          }
        `
      : ''}

  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: ${(props) => (props.alignBot ? 'flex-end' : 'center')};
  flex-direction: column;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    background: ${(props) => props.bg || 'transparent'};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${(props) => props.bgPosition || 'left'} center;
    ${(props) =>
      props.bgStatic
        ? css`
            background-attachment: fixed;

            @supports (-webkit-touch-callout: none) {
              background-attachment: scroll;
            }
          `
        : ''}
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const VideoAsBackground = styled.video`
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  object-fit: cover;
  background: ${(props) => props.bg || 'transparent'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => props.bgPosition || 'center'} center;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: 100vh;
  }
`;

const WrapperContainer = styled(RowWrapper)`
  padding: 0;
  margin: 0;
`;

const AboutSubSection = ({ children, item, videoType = 'video/mp4', windowDimensions }) => {
  const videoCase = item.video && windowDimensions.width > theme.breakpoints.showVideos;

  return (
    <WrapperContainer>
      {videoCase && (
        <VideoAsBackground autoPlay muted loop canplay poster={item.bg} bg={item.bg}>
          <source src={item.video} type={videoType} />
        </VideoAsBackground>
      )}

      <RowWrapper
        absolute
        id={item.id}
        alignBot={!!item.centerContent}
        bg={!videoCase ? item.bg : undefined}
        bgSm={!videoCase ? item.bgSm : undefined}
        bgPosition={item.bgPosition}
        bgStatic={item.bgStatic}
      >
        {children}
      </RowWrapper>
    </WrapperContainer>
  );
};

const InfoRowWrapper = styled(Row)`
  color: white;
  z-index: 10;

  h1 {
    font-size: ${(props) => props.theme.font.size.boxTitle};
    margin: 4px 0 20px 10px;
    text-align: left;
  }

  p,
  ul,
  li {
    font-family: ${(props) => props.theme.font.family.defaultFamily};
    font-size: ${(props) => props.theme.font.size.introText};
    line-height: ${(props) => props.theme.font.lineHeight.boxText};
    margin: 0px;
  }

  ul {
    text-align: left;
    padding-left: 0;
    list-style-type: none;

    li {
      text-align: left;
    }
  }

  p + p,
  li + li {
    margin-top: 14px;
  }

  img {
    border-radius: ${(props) => props.theme.borderRadius};
  }
`;

const ContentWrapper = styled.div`
  min-width: 250px;
  margin: ${(props) => props.theme.padding.basic} 0;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 0;
  }
`;

const DeeperBox = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.xs}) {
    padding-top: 30px;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    justify-content: center;
    padding-top: 0;
  }
`;

const InfoRow = ({
  leftContent = null,
  rightContent = null,
  centerContent = null,
  deeperContent = null,
  rowIndex = 1,
  windowDimensions
}) => {
  const imageIsLeft = rowIndex % 2 === 0; // Every second row should contain image on the left side (first row starts with image on the right side)
  const imageOrder = 2;
  const detailOrder = 1;
  const activeScenes = windowDimensions.width > theme.breakpoints.activateScenes;

  return centerContent ? (
    <InfoRowWrapper>
      <Col size={1} order={imageIsLeft ? imageOrder : detailOrder} alignSelf="center">
        <ContentWrapper>
          {activeScenes ? (
            <Scene duration={1} triggerHook={1} offset={5}>
              {(progress, event) => (
                <div>
                  <Tween
                    to={{
                      y: 0
                    }}
                    from={{
                      y: '2000px'
                    }}
                    paused
                    playState={getAnimationState(event)}
                    ease={theme.easing.basic}
                  >
                    <div>{centerContent}</div>
                  </Tween>
                </div>
              )}
            </Scene>
          ) : (
            centerContent
          )}
        </ContentWrapper>
      </Col>
    </InfoRowWrapper>
  ) : (
    <InfoRowWrapper>
      <Col size={1} order={imageIsLeft ? imageOrder : detailOrder} alignSelf="center">
        <ContentWrapper>
          {activeScenes ? (
            <Scene duration={1} triggerHook={1} offset={5}>
              {(progress, event) => (
                <div>
                  <Tween
                    to={{
                      x: 0
                    }}
                    from={{
                      x: '-2000px'
                    }}
                    paused
                    playState={getAnimationState(event)}
                    ease={theme.easing.basic}
                  >
                    <div>{leftContent}</div>
                  </Tween>
                </div>
              )}
            </Scene>
          ) : (
            leftContent
          )}
        </ContentWrapper>
      </Col>
      <Col size={1} order={imageIsLeft ? detailOrder : imageOrder} alignSelf="center">
        <ContentWrapper>
          {activeScenes ? (
            <Scene duration={1} triggerHook={1} offset={5}>
              {(progress, event) => (
                <div>
                  <Tween
                    to={{
                      x: '0px'
                    }}
                    from={{
                      x: '2000px'
                    }}
                    paused
                    playState={getAnimationState(event)}
                  >
                    <div>{rightContent}</div>
                  </Tween>
                </div>
              )}
            </Scene>
          ) : (
            rightContent
          )}
        </ContentWrapper>
      </Col>
    </InfoRowWrapper>
  );
};

const SectionsGroup = ({ id, infoRows }) => {
  const windowDimensions = useWindowDimensions();

  return (
    <AboutContainer direction="column" id={id}>
      {infoRows.map((item, itemIndex) => (
        <AboutSubSection item={item} windowDimensions={windowDimensions} key={`services-subsection-${itemIndex}`}>
          {item.linkPrevious && (
            <SectionSwitcher reverse to={`${item.linkPrevious}`} text={`${item.linkPreviousText}`} />
          )}
          <InfoRow
            key={`info-row-${itemIndex}`}
            leftContent={item.leftContent}
            rightContent={item.rightContent}
            centerContent={item.centerContent}
            rowIndex={itemIndex + 1}
            windowDimensions={windowDimensions}
          />

          {item.deeperContent && <DeeperBox>{item.deeperContent}</DeeperBox>}
          <SectionSwitcher dark={item.linkDark} to={`${item.linkTo}`} text={`${item.linkText}`} />
        </AboutSubSection>
      ))}
    </AboutContainer>
  );
};

export default SectionsGroup;
