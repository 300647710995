import React from 'react';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 0;
  word-break: break-word !important;
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  align-items: center;
  background-color: ${(props) => props.bgColor || props.theme.color.emptyBg};
  background-repeat: no-repeat;

  ${(props) =>
    props.bgGradient
      ? css`
          background: ${props.bgGradient};
        `
      : ''}

  ${(props) =>
    props.bgImg
      ? css`
          background: url(${props.bgImg});
          background-size: cover;
          background-position: center;
          background-attachment: fixed;

          @supports (-webkit-touch-callout: none) {
            background-attachment: scroll;
          }
        `
      : ''}
`;

const Container = ({ children, ...rest }) => <StyledContainer {...rest}>{children}</StyledContainer>;

export default Container;
