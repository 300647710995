import React from 'react';
import styled, { css } from 'styled-components';
import { getTitleDecorations } from '../../helpers/decorations';

const StyleCore = styled.div`
  text-align: center;
  font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const StyledTitle = styled.h2`
  img:before,
  img:after {
    content: '';
    display: block;
    width: 130px;
    height: 1px;
    position: absolute;
  }

  img:before {
    left: -200px;
    top: 50%;
    background-image: linear-gradient(to right, transparent, white);
  }

  img:after {
    left: 290px;
    top: 50%;
    background-image: linear-gradient(to left, transparent, white);
  }
`;

const TitleWrapper = styled.div`
  //position: relative;
`;

export const DefaultHeading = styled(StyleCore)`
  font-family: ${(props) => props.theme.font.family.titles1};
  font-size: ${(props) => props.size || props.theme.font.size.title3};
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.color.gold};
  text-shadow: red;
  margin-bottom: ${(props) => props.theme.padding.basic};

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: ${(props) => props.size || props.theme.font.size.title2};
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: ${(props) => props.size || props.theme.font.size.title1};
  }
`;

export const GoldenHeading = styled(DefaultHeading)`
  position: relative;
  display: block;
  text-shadow: 1px 2px 1px #5b3b08, 0 1px 5px #000;

  ${(props) =>
    props.shinyShadow
      ? css`
          text-shadow: 1px 2px 1px #5b3b08, 0 1px 5px #000, 0 0 20px #fff;
        `
      : ''}

  &:after {
    content: '${(props) => props.text}';
    text-shadow: none;
    color: transparent;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom right, #cc9a30 40%, #ebba6b 45%, #eccbb3 50%, #d3924b 56%, #bd9d50, #aa7011);
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SectionTitle = ({ children, decorated = true, shinyShadow = false }) => {
  return (
    <TitleWrapper>
      <GoldenHeading text={children} shinyShadow={shinyShadow}>
        {children}
      </GoldenHeading>
      {decorated && getTitleDecorations()}
    </TitleWrapper>
  );
};

export default SectionTitle;
