import React from 'react';
import { Controller } from 'react-scrollmagic';
import NFTsIntroSection from './sections/nfts/NFTsIntroSection';
import NFTsSections from './sections/nfts/NFTsSections';
import NFTsGallery from './sections/nfts/NFTsGallery';

const AboutPage = () => {
  return (
    <Controller>
      <NFTsIntroSection />
      <NFTsSections />
      <NFTsGallery />
    </Controller>
  );
};

export default AboutPage;
