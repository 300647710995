import React from 'react';
import styled from 'styled-components';
import Gallery from 'react-grid-gallery';
import SectionsGroup from '../SectionsGroup';
import BasicBox from '../../../components/box/BasicBox';
import { raceTeaser as GameProdVideo, environmentExample as CinematicProdVideo } from '../../../staticData/videoLinks';
import AboutBg01 from '../../../images/home/AoA_HomeSections_Bg01.jpg';
import AboutBg01Sm from '../../../images/home/AoA_HomeSections_Bg01-sm.jpg';
import AboutBg02 from '../../../images/home/AoA_HomeSections_Bg02.jpg';
import AboutBg02Sm from '../../../images/home/AoA_HomeSections_Bg02-sm.jpg';
import AboutBg04 from '../../../images/home/AoA_HomeSections_Bg04.jpg';
import AboutBg04Sm from '../../../images/home/AoA_HomeSections_Bg04-sm.jpg';
import AboutBg05 from '../../../images/home/AoA_HomeSections_RaceGame.jpg';
import AboutBg05Sm from '../../../images/home/AoA_HomeSections_Bg05-sm.jpg';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import SectionTitle from '../../../components/section/SectionTitle';
import ReadMoreButton from '../../../components/button/ReadMoreButton';
import { paths } from '../../../routes/paths';

function importAll(r) {
  return r.keys().map(r);
}

const ImagesArt = importAll(require.context('../../../images/home/nfts/', false, /\.(png|jpe?g|svg)$/));
const ImagesArtThumbs = importAll(require.context('../../../images/home/nfts/thumbs/', false, /\.(png|jpe?g|svg)$/));

const galleryRowHeight = 100;
const galleryImageMargin = 4;

const GalleryArt = ImagesArt.map((item, index) => ({
  src: item,
  thumbnail: ImagesArtThumbs[index],
  caption: 'Angels of Aether - NFTs'
}));

const GalleryWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  display: block;
  min-height: 120px;

  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;

const infoRows = [
  {
    leftContent: (
      <>
        <BasicBox transparent>
          <SectionTitle decorated={false}>About</SectionTitle>
          <ul>
            <li>
              Angels of Aether is a decentralized passion project focused on High Quality Art and Creativity. The core
              of the project consists of NFT collectibles, mainly generatively created with unique traits. The creators
              are expressing their artistic Visions through various formats, from 2D concepts, 2D illustrations, to 3D
              models, 3D environments, scenes, videos, storytelling and games.
            </li>
          </ul>
          <SectionSwitcher smallRelative to="/#home-nfts" text="" />
        </BasicBox>
      </>
    ),
    id: 'home-about',
    linkTo: '#home-nfts',
    linkText: 'NFTs',
    bg: `url(${AboutBg01})`,
    bgSm: `url(${AboutBg01Sm})`,
    bgPosition: 'center'
  },
  {
    rightContent: (
      <>
        <BasicBox transparent>
          <SectionTitle decorated={false}>NFTs</SectionTitle>
          <ul>
            <li>
              3D, Full-Body, High Resolution (5000x5000 pixel) NFT Collectibles on the Ethereum blockchain, with focus
              on high-quality & high detail artistic aesthetics - are the heart of this project.
            </li>
            <li>
              The first NFT collection of beautiful & unique angelic characters is called Angels of Aether. The second
              one - Guardians of Aether - depicts the angelic male counterparts.
            </li>
            <li>
              <ReadMoreButton to={`${paths.nfts}/#intro`}>Learn more</ReadMoreButton>
            </li>
          </ul>
          <GalleryWrapper>
            <Gallery
              images={GalleryArt}
              rowHeight={galleryRowHeight}
              margin={galleryImageMargin}
              enableImageSelection={false}
              preloadNextImage
            />
          </GalleryWrapper>
          <SectionSwitcher smallRelative to="/#home-world" text="" />
        </BasicBox>
      </>
    ),
    id: 'home-nfts',
    linkTo: '#home-world',
    linkText: 'World/Story',
    bg: `url(${AboutBg04})`,
    bgSm: `url(${AboutBg04Sm})`,
    bgPosition: 'center'
  },
  {
    leftContent: (
      <>
        <BasicBox transparent>
          <SectionTitle decorated={false}>World / Story</SectionTitle>
          <ul>
            <li>
              The story of the Angels of Aether takes place in a magical divine angelic dimension filled with Light and
              True Virtues in their highest form. The angelic world itself consists of several diversified realms, each
              of its own special character and each devoted to a specific Element. They are all ordered in a divine
              harmony and cocreate a wonderful and compact universe.
            </li>
            <li>
              In the Heart of the angelic realm, hovering in the clouds, is located the Crystal Capital City, the home
              base of the Angels of Aether.
            </li>
            <li>
              <ReadMoreButton to={`${paths.world}/#intro`}>Learn more</ReadMoreButton>
            </li>
          </ul>
          <SectionSwitcher smallRelative to="/#home-game" text="" />
        </BasicBox>
      </>
    ),
    // rightContent: (
    //   <Box width={100} height={100}>
    //     <VideoBox video={CinematicProdVideo} height="248px" width="440px" />
    //   </Box>
    // ),
    id: 'home-world',
    linkTo: '#home-game',
    linkText: 'Game Development',
    bg: `url(${AboutBg02})`,
    bgSm: `url(${AboutBg02Sm})`,
    video: CinematicProdVideo
  },
  {
    centerContent: (
      <>
        <SectionTitle decorated={false}>Game Development</SectionTitle>
        <BasicBox transparent>
          <ul>
            <li>
              The first 3D gaming experience of the Angels of Aether is a 3D competitive flying race game, taking place
              on a beautiful exotic island, where young brave angels sharpen their flying skills to earn the possibility
              to serve in the ranks of the angelic Legion of the Angels of Aether, among the best of the best.
            </li>
            <li>The Game is created in Unreal Engine 5 and is in development stage.</li>
            <li>
              <ReadMoreButton to={`${paths.game}/#intro`}>Learn more</ReadMoreButton>
            </li>
          </ul>
          <SectionSwitcher smallRelative to="/#home-expansions" text="" />
        </BasicBox>
      </>
    ),
    // rightContent: (
    //   <Box width={100} height={100}>
    //     <VideoBox video={GameProdVideo} height="248px" width="440px" />
    //     {/* <img src={ContentImg01} alt="Concept Art" height="200px" /> */}
    //   </Box>
    // ),
    id: 'home-game',
    linkTo: '#home-expansions',
    linkText: 'Ideas for Expansions',
    bg: `url(${AboutBg05})`,
    bgSm: `url(${AboutBg05Sm})`,
    bgPosition: 'center',
    video: GameProdVideo
  }
];

const HomeSections = () => <SectionsGroup id="section" infoRows={infoRows} />;

export default HomeSections;
