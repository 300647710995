import React from 'react';
import styled from 'styled-components';
import { BoxSubTitle, BoxTitle, BoxWrapper } from './BasicBox';
import Divider from '../divider/Divider';
import Icon from '../icon/Icon';
import openseaIcon from '../../images/icons/opensea.svg';
import { getBoxDecorationsByConfig } from '../../helpers/decorations';
import { useWindowResize } from '../../hooks/useWindowResize';

const IconBoxTitle = styled(BoxTitle)`
  text-align: center !important;
  width: 100% !important;
  margin: 0;
`;

const IconBoxSubTitle = styled(BoxSubTitle)`
  text-align: center !important;
  width: 100% !important;
  margin: 0;
`;

const StyledIcon = styled(Icon)`
  margin-top: ${(props) => props.theme.padding.half};
  margin-bottom: ${(props) => props.theme.padding.half};
  text-align: center;
`;

export const BoxContentWrapper = styled.div``;

const IconBoxContentWrapper = styled(BoxContentWrapper)`
  text-align: center !important;
`;

const IconBoxContent = styled.p`
  color: white;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title-wrapper {
    h4,
    h5 {
      text-align: left !important;
    }
  }
`;

const IconBox = ({
  title,
  icon = openseaIcon,
  subTitle = null,
  content = null,
  children = null,
  decorations = {},
  transparent = false,
  ...rest
}) => {
  const { width } = useWindowResize();

  const boxHeaderMobile = (
    <div>
      <div>
        <IconBoxTitle>{title}</IconBoxTitle>
        {subTitle && <IconBoxSubTitle>{subTitle}</IconBoxSubTitle>}
      </div>
      <Divider />
      <div>
        <StyledIcon icon={icon} color="white" height="60px" width="60px" />
      </div>
    </div>
  );

  const boxHeaderDesktop = (
    <>
      <HeaderWrapper>
        <div className="title-wrapper">
          <IconBoxTitle>{title}</IconBoxTitle>
          {subTitle && <IconBoxSubTitle>{subTitle}</IconBoxSubTitle>}
        </div>
        <div>
          <StyledIcon icon={icon} color="white" height="60px" width="60px" />
        </div>
      </HeaderWrapper>
      <Divider />
    </>
  );

  return (
    <BoxWrapper transparent={transparent} {...rest}>
      {getBoxDecorationsByConfig(decorations)}
      {width <= 576 ? boxHeaderMobile : boxHeaderDesktop}
      <IconBoxContentWrapper>
        {content && <IconBoxContent>{content}</IconBoxContent>}
        {children}
      </IconBoxContentWrapper>
    </BoxWrapper>
  );
};

export default IconBox;
