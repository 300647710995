import React from 'react';
import styled from 'styled-components';
import decoration from '../../images/decorations/button-decoration.png';

const StyledButtonDecoration = styled.img`
  height: 48px;
  width: 45px;
  z-index: 10;
  position: absolute;
  top: -2px;
`;

export const ButtonDecoration = (props) => {
  return <StyledButtonDecoration src={decoration} {...props} />;
};

export const ButtonDecorationLeft = styled(ButtonDecoration)`
  left: -36px;
`;

export const ButtonDecorationRight = styled(ButtonDecoration)`
  transform: rotate(180deg);
  right: -36px;
`;
