import React from 'react';
import styled from 'styled-components';
import decoration from '../../images/decorations/box-decoration.png';

const StyledDecoration = styled.img`
  height: 25px;
  width: 25px;
  position: absolute;
  z-index: 999;
`;

export const BoxDecoration = (props) => {
  return <StyledDecoration src={decoration} {...props} />;
};

export const BoxDecorationTopLeft = styled(BoxDecoration)`
  transform: rotate(180deg);
  left: 5px;
  top: 5px;
`;

export const BoxDecorationTopRight = styled(BoxDecoration)`
  transform: rotate(-90deg);
  right: 5px;
  top: 5px;
`;

export const BoxDecorationBottomLeft = styled(BoxDecoration)`
  transform: rotate(90deg);
  left: 5px;
  bottom: 5px;
`;

export const BoxDecorationBottomRight = styled(BoxDecoration)`
  transform: rotate(-180);
  right: 5px;
  bottom: 5px;
`;
