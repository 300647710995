import React from 'react';
import styled from 'styled-components';
import BasicBox from './BasicBox';

export const IntroBox = styled(BasicBox)`
  margin-bottom: 60px;
  font-family: ${(props) => props.theme.font.family.defaultFamily};
  font-size: ${(props) => props.theme.font.size.introText};
  line-height: ${(props) => props.theme.font.lineHeight.introText};
`;

export default IntroBox;
