import React from 'react';
import './styles/fonts.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import theme from './styles/theme';
import AppWrapper from './components/AppWrapper';
import Header from './components/header/Header';
import { routes } from './routes/routes';
import Footer from './components/footer/Footer';
import MobileMenu from './components/mobileMenu/MobileMenu';
import HomePage from './pages/HomePage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppWrapper>
          <MobileMenu />
          <Header />

          <Routes>
            {routes.map((route) => (
              <Route key={`route-to-${route.path}`} exact={route.exact} path={route.path} element={route.component} />
            ))}
            <Route path="*" component={HomePage} />
          </Routes>
          <Footer />
        </AppWrapper>
      </Router>
    </ThemeProvider>
  );
}

export default App;
