import React from 'react';
import styled, { css } from 'styled-components';
import DefaultGeneralBg from '../../images/DefaultGeneralBg.jpg';

const StyledSection = styled.section`
  // if video is in background. no padding is set
  padding: ${(props) => (props.video ? '' : `${props.theme.padding.basic} ${props.theme.padding.half}`)};
  margin: auto;
  box-sizing: border-box;

  /* Make the section as high as the screen */
  ${(props) => (props.fullHeight ? 'min-height: 100vh;' : '')}

  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyDown ? 'flex-end' : 'center')};
  align-items: center;

  position: relative;

  /* Apply background color */
  ${(props) => (props.bg ? `background-color: ${props.bg};` : '')}

  /* Apply small background image */
  background: url(${(props) => props.bgImgSm || props.bgImg || DefaultGeneralBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => props.bgPosition || 'center'} center;
  ${(props) =>
    props.bgStatic
      ? css`
          background-attachment: fixed;

          @supports (-webkit-touch-callout: none) {
            background-attachment: scroll;
          }
        `
      : ''}

  /* Remove background if it is broken under content */
  ${(props) =>
    props.breakBackground
      ? css`
          @media only screen and (max-width: ${props.breakAt}) {
            background: transparent;
          } ;
        `
      : ''}

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;

    /* Apply desktop background image */
    background: url(${(props) => props.bgImg || DefaultGeneralBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${(props) => props.bgPosition || 'center'} center;
    ${(props) =>
      props.bgStatic
        ? css`
            background-attachment: fixed;

            @supports (-webkit-touch-callout: none) {
              background-attachment: scroll;
            }
          `
        : ''}

    padding: ${(props) => (props.video ? '' : `${props.theme.padding.basic} ${props.theme.padding.triple}`)};
    ${(props) =>
      props.paddingTop
        ? css`
            padding-top: ${props.paddingTop};
          `
        : ''}
    ${(props) =>
      props.paddingBottom
        ? css`
            padding-bottom: ${props.paddingBottom};
          `
        : ''}
  }
`;

export default StyledSection;
