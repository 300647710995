import React from 'react';
import Section from '../components/section/Section';
import SectionTitle from '../components/section/SectionTitle';
import bgImg from '../images/FAQBg.jpg';
import BasicBox from '../components/box/BasicBox';
import { externalLinks } from '../staticData/LinkNodes';

const items = [
  {
    title: 'Collecting Personal Information',
    description: (
      <>
        <p>We do not collect any personal information.</p>
      </>
    )
  },
  {
    title: 'Random draw lottery / Giveaways',
    description: (
      <>
        <p>
          Sometimes we do some free giveaways / random draws - particular number of wallets of Angels of Aether NFT
          holders or volunteered participants, who will share the wallet address and Angel number on twitter, will be
          drawn and the winners will obtain free Angels of Aether NFT. The wallet addresses are used only to send the
          NFTs, they will not be stored.
        </p>
      </>
    )
  },
  {
    title: 'Changes to the Privacy Policy',
    description: (
      <>
        <p>
          Changes to the Privacy Policy may be changed or updates at any time, in order to be aligned with changes in
          practices, or for legal, regulatory or other operational reasons.
        </p>
      </>
    )
  }
];

const PrivacyPage = () => {
  return (
    <Section id="privacy" fullHeight fullContainerWidth bgImg={bgImg} bgStatic paddingTop="200px" paddingBottom="140px">
      <SectionTitle decorated={false}>Privacy policy</SectionTitle>
      {items.map((item) => (
        <BasicBox
          fullPercWidth
          transparent
          title={item.title}
          subTitle={item.subtitle || ''}
          decorations={item.decoration || { topLeft: true, bottomRight: true }}
        >
          {item.description}
        </BasicBox>
      ))}
    </Section>
  );
};

export default PrivacyPage;
