import React from 'react';
import styled from 'styled-components';
import { Tween } from 'react-gsap';
import { Scene } from 'react-scrollmagic';
import BasicSection from './BasicSection';
import theme from '../../styles/theme';
import Container from '../container/Container';
import { getAnimationState } from '../../helpers/animations';
import useWindowDimensions from '../../utils/useWindowDimensions';

const BgImage = styled.div`
  display: none;

  @media only screen and (max-width: ${(props) => props.breakAt}) {
    width: 100vw;
    height: 50vh;
    display: block;
    background: url(${(props) => props.bgImg});
    background-size: cover;
    background-position: center;
  }
`;

const VideoAsBackground = styled.video`
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

/**
 * breakDirection: 'top' | 'bottom'
 */
const Section = ({
  children = false,
  animateLeft = false,
  animateRight = false,
  fullContainerHeight = false,
  fullContainerWidth = false,
  videoType = 'video/mp4',
  ...rest
}) => {
  const {
    breakDirection = 'bottom',
    breakBackground = false,
    breakAt = theme.breakpoints.sm,
    bgImg,
    bgImgSm,
    video
  } = rest;
  const windowDimensions = useWindowDimensions();
  const activeScenes = windowDimensions.width > theme.breakpoints.activateScenes;
  const videoCase = video && windowDimensions.width > theme.breakpoints.showVideos;

  const wrapWithScene = (content) => (
    <Scene duration={1} triggerHook={1} offset={5}>
      {(progress, event) => (
        <div>
          {activeScenes ? (
            <Tween
              to={{
                x: 0
              }}
              from={{
                x: animateLeft ? '-2000px' : '2000px'
              }}
              paused
              playState={getAnimationState(event)}
              ease={theme.easing.basic}
            >
              <div>{content}</div>
            </Tween>
          ) : (
            content
          )}
        </div>
      )}
    </Scene>
  );

  return (
    <BasicSection breakBackground={breakBackground} breakAt={breakAt} bgImg={bgImg} bgImgSm={bgImgSm} {...rest}>
      {videoCase && (
        <VideoAsBackground autoPlay muted loop canplay>
          <source src={video} type={videoType} />
        </VideoAsBackground>
      )}

      <Container absolute={!!video} fullHeight={fullContainerHeight} fullWidth={fullContainerWidth} alignBot>
        {/* breakBackground && breakDirection === 'top' && <BgImage bgImg={bgImg} breakAt={breakAt} alt="." /> */}
        {animateLeft || animateRight ? wrapWithScene(children) : children}
        {/* breakBackground && breakDirection === 'bottom' && <BgImage bgImg={bgImg} breakAt={breakAt} alt="." /> */}
      </Container>
    </BasicSection>
  );
};

export default Section;
