import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

export const StyledButton = styled(Link)`
  font-size: ${(props) => props.theme.font.size.buttons.learnMore};
  font-family: 'Cinzel-Bold';
  color: ${(props) => props.color || props.theme.color.readMoreButton};
  text-decoration: none;

  background: transparent;
  border: 0;
  cursor: pointer;
  margin-bottom: ${(props) => props.margin || 0}px;

  padding: ${(props) => props.theme.padding.half} 0;
  white-space: nowrap;

  &:hover {
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ff, 0 0 80px #0ff;
  }
`;

export const HashButton = styled(HashLink)`
  font-size: ${(props) => props.theme.font.size.buttons.learnMore};
  font-family: 'Cinzel-Bold';
  color: ${(props) => props.color || props.theme.color.readMoreButton};
  text-decoration: none;

  background: transparent;
  border: 0;
  cursor: pointer;
  margin-bottom: ${(props) => props.margin || 0}px;

  padding: ${(props) => props.theme.padding.half} 0;
  white-space: nowrap;

  &:hover {
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ff, 0 0 80px #0ff;
  }
`;

const ReadMoreButton = ({ children, margin, to, color, simpleLink }) => {
  return simpleLink ? (
    <StyledButton margin={margin} to={to} color={color}>
      {children || 'Read more'}
    </StyledButton>
  ) : (
    <HashButton margin={margin} to={to} smooth color={color}>
      {children || 'Read more'}
    </HashButton>
  );
};

export default ReadMoreButton;
