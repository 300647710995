import React from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.div`
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.height || '30px'};
  background-color: ${(props) => props.color || 'black'};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  margin-left: auto;
  margin-right: auto;
  ${(props) => (props.rotate ? 'transform: rotate(180deg);' : '')}

  &:hover {
    background-color: ${(props) => props.hoverColor || 'grey'};
    box-shadow: 0 0 5px ${(props) => props.hoverColor}, 0 0 10px ${(props) => props.hoverColor},
      0 0 20px ${(props) => props.hoverColor}, 0 0 40px ${(props) => props.hoverColor};
  }
`;

const Icon = (props) => <StyledIcon {...props} />;

export default Icon;
