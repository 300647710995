import React, { useState } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import bgImg from '../../images/Mobile_menu_bg.jpg';
import { mobileLinks } from '../../staticData/MenuItems';
import logo from '../../images/AV_logo-m.png';
import IconClose from '../../images/icons/times.svg';
import IconMenu from '../../images/icons/bars.svg';
import Icon from '../icon/Icon';

const MobileMenuStyled = styled.header`
  width: 100%;
  height: 60px;
  top: 0;
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: 1000;
  display: none;
  justify-content: flex-end;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.showMobileMenu}) {
    display: flex;
  }
`;

const MenuToggle = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: rgba(88, 88, 88, 0.4);
  cursor: pointer;
  z-index: 1100;
  margin: 0 20px;
  margin-top: 20px;

  &.openMenu {
    background-color: rgba(158, 126, 59, 0.5);
  }
`;

const MenuContainer = styled.div`
    height: 0;
    display: flex;
    transition: height 300ms;
    overflow-y: auto;
    width: 100%;
    z-index: 1;
    background-color: ${(props) => props.theme.color.mobileMenuBackground};
    background-image: url('${bgImg}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /*padding-top: calc(var(--header-height) * 2);*/
    /*padding-bottom: calc(var(--header-height) * 2);*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    a {
      display: none;
      transition: display 100ms;
      font-family: ${(props) => props.theme.font.family.titles1};
      color: ${(props) => props.theme.color.footerLink};
      font-style: normal;
      font-size: ${(props) => props.theme.font.size.menuItem};
      text-decoration: none;
      text-transform: uppercase;
      user-select: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      cursor: pointer;
      
      &.sublink {
        color: ${(props) => props.theme.color.mobileMenuItem};
        font-size: ${(props) => props.theme.font.size.menuSubItem};
      }
      
      &:hover, &.sublink:hover {
        color: ${(props) => props.theme.color.footerLinkHover};
        text-shadow: 0 0 5px ${(props) => props.theme.color.footerLinkHoverShadow},
          0 0 10px ${(props) => props.theme.color.footerLinkHoverShadow},
          0 0 20px ${(props) => props.theme.color.footerLinkHoverShadow},
          0 0 40px ${(props) => props.theme.color.footerLinkHoverShadow};
      }
    }
    
    img {
      display: none;
      transition: display 100ms;
    }

    &.shown {
      height: 100vh;
      transition: height 300ms, opacity 200ms;

      a {
        display: flex;
        transition: display 100ms;
        
         &:hover {
            text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #efdcba;
          }
      }
      
      img {
        display: flex;
        transition: display 100ms;
      }
    }

    @media only screen and (--desktop) {
      display: none;
    }
  }
`;

const LogoImg = styled.img`
  height: 80px;
  margin: 0;
  padding: 0;
  user-select: none;
`;

const LogoHash = styled(HashLink)`
  height: 80px;
  margin: 0;
  padding: 0;
  user-select: none;
`;

const LogoLink = ({ onClick }) => (
  <LogoHash smooth to="#intro" onClick={onClick}>
    <LogoImg src={logo} />
  </LogoHash>
);

const MobileMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <MobileMenuStyled id="header" role="banner" className="header">
      <MenuToggle
        aria-label="icon-menu"
        type="button"
        id="menu-toggle"
        className={isMobileMenuOpen ? 'openMenu' : ''}
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
        }}
      >
        <Icon icon={isMobileMenuOpen ? IconClose : IconMenu} color="white" />
      </MenuToggle>
      <MenuContainer className={`${isMobileMenuOpen ? ' shown' : ''}`}>
        <LogoLink
          onClick={() => {
            setIsMobileMenuOpen(!isMobileMenuOpen);
          }}
        />
        {mobileLinks.map((link, itemIndex) => (
          <HashLink
            to={link.linkTo}
            key={`mobile-menu-item-${itemIndex}`}
            class={link.sublink ? 'sublink' : ''}
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
          >
            {link.title || ''}
          </HashLink>
        ))}
      </MenuContainer>
    </MobileMenuStyled>
  );
};

export default MobileMenu;
