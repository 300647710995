import React from 'react';
import Section from '../../../components/section/Section';
import SectionTitle from '../../../components/section/SectionTitle';
import bgImg from '../../../images/world/story/bg/StorySectionBg.jpg';
import bgImgSm from '../../../images/world/story/bg/StorySectionBg-sm.jpg';
import BasicBox from '../../../components/box/BasicBox';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import { paths } from '../../../routes/paths';

const items = [
  {
    title: 'The Mission',
    description: (
      <>
        <p>
          {`She flew through the sunlit clouds, on the edge of the Celestial Plane. Little rainbow reflections shimmering
          on her wings. Heavenly refreshing sensation grew in her Soul more and more as she was nearing to the Divine
          Source Light. "Welcome Arcania" she heard in her Heart.`}
        </p>
        <p>
          {`"We have a mission for you. The creativity on Earth is going in the right direction. Hence, new levels of
          lessons and temptations will arise. We need you to aid people to maintain and increase their Resonance
          Frequency & Vibration and bring Blessings and Guidance to them."`}
        </p>
        <p>
          {`It has been Eons ago, since she was introduced to her first mission, but she remembered it as if it was
          yesterday. She was one of the few powers manifested to create a whole new universe based on Natural Order and
          the Highest Virtues. She was there, when Aether-Verse was born.`}
        </p>
      </>
    )
  },
  {
    title: 'Angelic Realms',
    description: (
      <>
        <p>
          The Core concept of Aether-Verse was shaped by the idea of Divine Love and Harmony. Its purpose was to serve
          as an inspiration and expand to such extent, as to be able to provide help to other Worlds. Multiple realms of
          Aether-Verse were formed; the Central one being Agarthea – the Angelic Capital City, situated in the very
          Heart of this Multiverse – the sky city of Divine Presence, floating on magical islands in the clouds. This is
          the place, where Arcania lives, in her Crystal Angelic Palace, right in the Center of the City.
        </p>
        <p>
          For the mission to Earth Arcania assembled a Legion of her best Angels, all of them ready to offer their All
          in aiding Humanity. Along with them, a group of 10 extraordinary Angels were selected – High Angels of Aether
          – Leaders with rare abilities within the ranks of Angels. Their purpose is to guide larger groups of Angels
          and help with more serious tasks.
        </p>
        <p>
          Even with a Legion of Angels, to handle the situation on Earth is a very challenging undertaking. After some
          time and a significant amount of good work, Arcania realized that more help is needed. She calmed her mind,
          entered her Heart and focused on her request for help.
        </p>
      </>
    )
  },
  {
    title: 'Guardians',
    description: (
      <>
        <p>
          {`"We are here." It was the voice of Auris, Lord of the Guardians. Arcania heard him in her Heart, she felt him
        strongly there. An exceptional feeling concentrated in her Light Body, uplifting warm waves floating from the
        Heart, her Aura began to shine brighter. She burned as a Sacred Beacon of Love ... after all, He was Her Twin
        Flame.`}
        </p>
        <p>
          And He brought the whole Legion of the Guardians of Aether, the masculine counterparts of the Angels. They
          came to aid their loved ones, to provide support and work together. Their Sacred Reunions will be seen Far and
          Wide and will bring Blessings and Divine Love to All.
        </p>
        <p>
          The arrival of the Guardians was a very good move. The overall energy potential of united Angels and Guardians
          was elevated exponentially. And the angelic vibes of Love and Harmony, they were spreading, were much
          stronger. The Humanity was exposed to higher Divine Light, thus strenghtening Virtues like Compassion,
          Kindness, Goodness; and also on the other hand, people were able to look closer at their inner shadows and
          bring their inner darkness into the light and that way being able to dissolve it.
        </p>
        <p>
          With help of the Guardians, the High Angels were able to focus deeper on tasks, which were more specific to
          their own field of expertise. Now they could approach people also on individual level and use their
          extraordinary skills to help them personally.
        </p>
      </>
    )
  }
];

const Story = () => {
  return (
    <Section id="story" fullHeight bgImg={bgImg} bgImgSm={bgImgSm} bgStatic paddingTop="140px" paddingBottom="80px">
      <SectionTitle decorated={false}>Story</SectionTitle>
      {items.map((item) => (
        <BasicBox
          transparent
          title={item.title}
          subTitle={item.subtitle || ''}
          decorations={item.decoration || { topLeft: true, bottomRight: true }}
        >
          {item.description}
        </BasicBox>
      ))}
      <SectionSwitcher to={`${paths.world}/#gallery`} text="World Gallery" showAlways smallRelative />
    </Section>
  );
};

export default Story;
