import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import ArrowDown from '../../images/icons/chevron-down.svg';
import Icon from '../../components/icon/Icon';
import theme from '../../styles/theme';

const Wrapper = styled.div`
  width: 100%;
  position: ${(props) => (props.smallRelative ? 'relative' : 'absolute')};
  ${(props) => (props.reverse ? 'top: 15px;' : 'bottom: 0;')}
  cursor: pointer;
  display: ${(props) => (props.smallRelative && !props.showAlways ? 'none' : 'flex')};
  justify-content: center;
  ${(props) => (props.smallRelative ? 'margin-bottom: 0px; margin-top: 15px;' : 'margin-bottom: 15px;')}
  user-select: none;

  .switcher-link {
    text-decoration: none;
    user-select: none;
    opacity: 0.7;

    .switcher-text {
      font-size: 12px;
      font-family: 'Cinzel-Bold';
      user-select: none;
      color: ${(props) => (props.dark ? props.theme.color.darkSwitcher : props.theme.color.lightSwitcher)};
      ${(props) => (props.reverse ? 'transform: rotate(180deg);' : '')}

      @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
        display: ${(props) => (props.showAlways ? 'flex' : 'none')};
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: ${(props) => (props.smallRelative || props.showAlways ? 'flex' : 'none')};
  }
`;

const SectionSwitcher = ({ to, text, reverse, smallRelative, showAlways, dark }) => {
  return (
    <Wrapper reverse={reverse} smallRelative={smallRelative} showAlways={showAlways} dark={dark}>
      {reverse ? (
        <HashLink className="switcher-link" smooth to={to}>
          <Icon
            icon={ArrowDown}
            color={dark ? theme.color.darkSwitcher : theme.color.lightSwitcher}
            className="icon"
            rotate
          />
          <span className="switcher-text">{text || ''}</span>
        </HashLink>
      ) : (
        <HashLink className="switcher-link" smooth to={to}>
          <span className="switcher-text">{text || ''}</span>
          <Icon icon={ArrowDown} color={dark ? theme.color.darkSwitcher : theme.color.lightSwitcher} className="icon" />
        </HashLink>
      )}
    </Wrapper>
  );
};

export default SectionSwitcher;
