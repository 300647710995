import React from 'react';
import {
  BoxDecorationBottomLeft,
  BoxDecorationBottomRight,
  BoxDecorationTopLeft,
  BoxDecorationTopRight
} from '../components/box/BoxDecorations';
import { ButtonDecorationLeft, ButtonDecorationRight } from '../components/button/ButtonDecorations';
import { TitleDecorationLeft, TitleDecorationRight } from '../components/section/SectionTitleDecorations';

export const getBoxDecorationsByConfig = (configuration) => {
  const decorations = [];
  if (configuration.topLeft) {
    decorations.push(<BoxDecorationTopLeft key="tl" />);
  }
  if (configuration.topRight) {
    decorations.push(<BoxDecorationTopRight key="tr" />);
  }
  if (configuration.bottomLeft) {
    decorations.push(<BoxDecorationBottomLeft key="bl" />);
  }
  if (configuration.bottomRight) {
    decorations.push(<BoxDecorationBottomRight key="br" />);
  }
  return decorations;
};

export const getButtonDecorationsByConfig = () => {
  const decorations = [];
  decorations.push(<ButtonDecorationLeft key="button-decoration-left" />);
  decorations.push(<ButtonDecorationRight key="button-decoration-right" />);
  return decorations;
};

export const getTitleDecorations = () => {
  const decorations = [];
  decorations.push(<TitleDecorationLeft key="title-decoration-left" />);
  decorations.push(<TitleDecorationRight key="title-decoration-right" />);
  return decorations;
};
