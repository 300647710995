import React from 'react';
import bgImg from '../../../images/home/gallery/bg/HomeGalleryBg.jpg';
import bgImgSm from '../../../images/home/gallery/bg/HomeGalleryBg-sm.jpg';
import GeneralGallerySection from '../GeneralGallerySection';
import Col from '../../../components/grid/Col';
import ReadMoreButton from '../../../components/button/ReadMoreButton';
import { paths } from '../../../routes/paths';
import BasicBox from '../../../components/box/BasicBox';

function importAll(r) {
  return r.keys().map(r);
}

const ConceptsIms = importAll(require.context('../../../images/home/gallery/concepts/', false, /\.(png|jpe?g|svg)$/));
const ConceptsThumbs = importAll(
  require.context('../../../images/home/gallery/concepts/thumbs/', false, /\.(png|jpe?g|svg)$/)
);
const AngelsIms = importAll(require.context('../../../images/home/gallery/nfts/', false, /\.(png|jpe?g|svg)$/));
const AngelsThumbs = importAll(
  require.context('../../../images/home/gallery/nfts/thumbs/', false, /\.(png|jpe?g|svg)$/)
);
const IllustrationsIms = importAll(
  require.context('../../../images/home/gallery/illustrations/', false, /\.(png|jpe?g|svg)$/)
);
const IllustrationsThumbs = importAll(
  require.context('../../../images/home/gallery/illustrations/thumbs/', false, /\.(png|jpe?g|svg)$/)
);
const RendersIms = importAll(require.context('../../../images/home/gallery/3D/', false, /\.(png|jpe?g|svg)$/));
const RendersThumbs = importAll(
  require.context('../../../images/home/gallery/3D/thumbs/', false, /\.(png|jpe?g|svg)$/)
);

const galleryRowHeight = 100;
const galleryImageMargin = 4;

const ConceptsImages = ConceptsIms.map((item, index) => ({
  src: item,
  thumbnail: ConceptsThumbs[index],
  caption: 'Angels of Aether - Concepts and Evolution Views'
}));

const AngelsImages = AngelsIms.map((item, index) => ({
  src: item,
  thumbnail: AngelsThumbs[index],
  caption: 'Angels of Aether - Angels & Guardians'
}));

const IllustrationsImages = IllustrationsIms.map((item, index) => ({
  src: item,
  thumbnail: IllustrationsThumbs[index],
  caption: 'Angels of Aether - Illustrations'
}));

const RendersImages = RendersIms.map((item, index) => ({
  src: item,
  thumbnail: RendersThumbs[index],
  caption: 'Angels of Aether - 3D'
}));

const imageGroups = [
  {
    title: 'Concepts',
    images: ConceptsImages
  },
  {
    title: 'Angels & Guardians',
    images: AngelsImages
  },
  {
    title: 'Illustrations',
    images: IllustrationsImages
  },
  {
    title: '3D',
    images: RendersImages
  }
];

const customInfo = (
  <BasicBox
    fullPercWidth
    transparent
    subTitle="Take a look at specific detailed galleries:"
    decorations={{ topRight: true, bottomLeft: true }}
    marginTop="40px"
  >
    <Col>
      <ReadMoreButton to={`${paths.nfts}/#gallery`}>NFT Gallery</ReadMoreButton>
      <br />
      <br />
      <ReadMoreButton to={`${paths.world}/#gallery`}>World Gallery</ReadMoreButton>
      <br />
      <br />
      <ReadMoreButton to={`${paths.game}/#gallery`}>Race Game Gallery</ReadMoreButton>
      <br />
    </Col>
  </BasicBox>
);

const HomeGallery = () => (
  <GeneralGallerySection
    title="General Gallery"
    noGroupTitles
    customInfo={customInfo}
    bgImg={bgImg}
    bgImgSm={bgImgSm}
    imageGroups={imageGroups}
    galleryRowHeight={galleryRowHeight}
    galleryImageMargin={galleryImageMargin}
  />
);

export default HomeGallery;
