import React from 'react';
import styled from 'styled-components';

const StyledCol = styled.div`
  position: relative;
  text-align: center;
  align-self: flex-start;
  margin: 0 auto;
  flex: ${(props) => props.size};

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    ${(props) => (props.order ? `order: ${props.order}` : '')}
  }
`;

const Col = ({ size = 1, children, ...rest }) => (
  <StyledCol size={size} {...rest}>
    {children}
  </StyledCol>
);

export default Col;
