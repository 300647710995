import React from 'react';
import Section from '../components/section/Section';
import SectionTitle from '../components/section/SectionTitle';
import bgImg from '../images/FAQBg.jpg';
import BasicBox from '../components/box/BasicBox';

const items = [
  {
    title: 'Disclaimer',
    description: (
      <>
        <p>
          Angels of Aether is a NFT trait-generative collectibles project utilizing the Ethereum blockchain, created by
          a decentralized team of artists, developers and NFT collectors.
        </p>
        <p>
          Angels of Aether is a decentralized project, where the rights reserved are granted to the creators of the
          project, including intellectual property rights surrounding “Angels of Aether” / “Guardians of Aether” names,
          logos, trademarks, the website, the design of the User Interface and UX, the smart contract code, fonts, and
          all the generative traits used of the “Angels of Aether” / “Guardians of Aether”. Angels of Aether reserve the
          exclusive rights to use official “Angels of Aether” / “Guardians of Aether” logos, texts, and other content
          associated with this project, our website, Twitter and Discord channels for any future advertising or
          promotion purposes.
        </p>
        <p>
          By purchasing/minting any Angels of Aether NFT or Guardians of Aether NFT, the user/buyer agrees to these
          Terms & Conditions.
        </p>
      </>
    )
  },
  {
    title: 'User Ownership Rights',
    description: (
      <>
        <p>
          Angels of Aether is composed of 5,123 individual ERC-721 tokens, referred to as NFT’s, and Guardians of Aether
          is composed of 3,500 individual ERC-721 tokens, referred to as NFT’s, all of which have associated and unique
          generative traits, corresponding to the art hosted on our servers.. After connecting an Ethereum-based wallet
          such as Metamask, and minting an Angel / Guardian using our highly secure smart contract, users gain full
          ownership of the minted NFT. This includes Exclusive Commercial Rights, meaning for example, that users can
          put their Angels / Guardians on merchandise/clothing, use them in video games and other projects, and
          basically have the right to do anything with your Angels / Guardians, as long as you are the owner.
        </p>
        <p>
          Any transfers and/or trades, where the Angels of Aether / Guardians of Aether are no longer in the possession
          of the user, all rights are null and void. Users are forbidden to use the name “Angels of Aether” / “Guardians
          of Aether” on any commercial products, unless it is connected with a specific “#”, signifying your direct
          ownership of the NFT. By purchasing an NFT, users agree not to use their Angels / Guardians in any project
          that involves pornography, hate speech, any “hate propaganda”, or any other illegal content.
        </p>
        <p>
          Users can use their Angels in other derivative works, as long as one owns the Angels / Guardians at the time
          of creating the derivative works. Users receive a High Resolution image of their Angels upon purchase of their
          Angels / Guardians, which is downloadable, and usable for any derivative works. Users may also assign those
          rights to third-party projects and creators including artists, as long as one owns the Angels / Guardians at
          the time of granting such permissive use, before the creation of derivative works.
        </p>
      </>
    )
  },
  {
    title: 'Third Party Uses',
    description: (
      <>
        <p>
          Users in ownership of Angels / Guardians, or third party users are not restricted from operating an NFT
          Marketplace or Third-party website that allows for the sale of Angels of Aether / Guardians of Aether, as long
          as the website or marketplace is able to verify the ownership via smart contract, ensuring a user is the sole
          owner, and can display their Angels / Guardians. Third party websites have the right, and ability to build
          tools in order to track traits or sales, and may use this information freely, as long as it displays the
          correct information, and uses appropriate tools to display this information.
        </p>
      </>
    )
  },
  {
    title: 'Smart Contract Conditions',
    description: (
      <>
        <p>
          Our developer team has ensured through vigorous testing methodologies, that our smart contract is bug-free,
          and the entire process is smooth and seamless. However, anything that may interrupt the smooth transition of
          the sales / minting and any other smart contract complexities that we may encounter, is taken into full
          consideration. By agreeing to our Terms & Conditions, users are agreeing to hold the creators of Angels of
          Aether not responsible for any potential losses incurred as a consequence of minting an NFT on
          angelsofaether.com. Potential losses may include gas fees lost for failed transactions, excessive gas fees
          charged due to high-traffic or Ethereum network congestion, and other complications that may arise due to a
          variety of issues and/or bugs in the UI/UX of Angels of Aether.
        </p>
      </>
    )
  },
  {
    title: 'Guarantees and/or Future Decision Making Processes',
    description: (
      <>
        <p>
          Angels of Aether is dedicated to continue in the development of the project and community, as outlined in our
          Milestones, with the intention to work towards each and every point outlined in our Milestones. However we
          cannot guarantee that every single point will be accomplished, as a wide range of hazardous occurences may
          happen that may stall or halt any progress. Furthermore, we reserve the rights to change/alter any and all the
          points in the Milestones. Various decisions can be made, that are the best next steps for the project
          development, in order to maintain a long-term vision, with potential expansion into a variety of platforms,
          projects, and fields of influence within the NFT focused community.
        </p>
        <p>
          Agreeing to the Terms & Conditions, users agree that the purchase of Angels of Aether NFT is all that a user
          is guaranteed to receive in their initial purchase. Any and all future airdrops, community benefits, goals,
          completion of steps within the milestones are not to be taken into consideration with the initial purchase of
          an NFT on angelsofaether.com, meaning users agreeing to Terms & Conditions do not rely on future commitments
          by Angels of Aether, while participating in the launch of Angels of Aether NFT’s on the website
          angelsofaether.com.
        </p>
        <p>
          Angels of Aether are not intended as any type of investment vehicle or investment, and there are absolutely no
          guarantees on the worth of Angels of Aether, as the NFT’s are treated as a collectible, or digital art being
          collected on the Ethereum network.
        </p>
      </>
    )
  },
  {
    title: 'Taxes and Liabilities',
    description: (
      <>
        <p>
          All users are entirely responsible for any taxes and liabilities arising from minting and/or reselling Angels
          of Aether/Guardians of Aether NFT’s. Please visit the appropriate institutions/accounting services, applicable
          in your country or jurisdiction.
        </p>
      </>
    )
  },
  {
    title: 'Age Implications',
    description: (
      <>
        <p>
          The purchase, selling, or manipulation of Angels of Aether in any shape or form is intended for individuals
          above the legal age in any particular jurisdiction, applicable based on your jurisdiction/location.
        </p>
      </>
    )
  }
];

const TermsPage = () => {
  return (
    <Section id="terms" fullHeight fullContainerWidth bgImg={bgImg} bgStatic paddingTop="200px" paddingBottom="140px">
      <SectionTitle decorated={false}>Terms & Conditions</SectionTitle>
      {items.map((item) => (
        <BasicBox
          fullPercWidth
          transparent
          title={item.title}
          subTitle={item.subtitle || ''}
          decorations={item.decoration || { topLeft: true, bottomRight: true }}
        >
          {item.description}
        </BasicBox>
      ))}
    </Section>
  );
};

export default TermsPage;
