import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
  width: 100%;
  min-height: 100%;
  max-width: ${(props) => props.theme.dimensions.maxWidth};
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: flex-start;
  align-content: center;
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  flex-wrap: wrap;
  box-sizing: border-box;

  @media only screen and (max-width: ${(props) =>
      props.lowerBreak ? props.theme.breakpoints.sm : props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

const Row = ({ children, ...rest }) => <StyledRow {...rest}>{children}</StyledRow>;

export default Row;
