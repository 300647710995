import React from 'react';
import { Controller } from 'react-scrollmagic';
import IntroSection from './sections/world/WorldIntro';
import Story from './sections/world/WorldStory';
import Biomes from './sections/world/WorldBiomes';
import WorldGallery from './sections/world/WorldGallery';

const HomePage = () => {
  return (
    <Controller>
      <IntroSection />
      <Biomes />
      <Story />
      <WorldGallery />
    </Controller>
  );
};

export default HomePage;
