import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled, { css } from 'styled-components';
import IconTwitter from '../../images/icons/twitter.svg';
import IconOpensea from '../../images/icons/opensea.svg';
import IconDiscord from '../../images/icons/discord.svg';
import IconYoutube from '../../images/icons/youtube.svg';
import logo from '../../images/AV_logo.png';
import Row from '../grid/Row';
import Col from '../grid/Col';
import Icon from '../icon/Icon';
import theme from '../../styles/theme';
import FooterBg from '../../images/FooterBg.jpg';
import FooterBgSm from '../../images/FooterBg-sm.jpg';
import { paths } from '../../routes/paths';
import { externalLinks } from '../../staticData/LinkNodes';

const FooterLinkWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const FooterLinkHash = styled(HashLink)`
  color: ${(props) => props.theme.color.footerLink};
  font-family: ${(props) => props.theme.font.family.titles1};
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    color: ${(props) => props.theme.color.footerLinkHover};
    text-shadow: 0 0 5px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 10px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 20px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 40px ${(props) => props.theme.color.footerLinkHoverShadow};
  }
`;

const FooterLink = styled(Link)`
  color: ${(props) => props.theme.color.footerLink};
  font-family: ${(props) => props.theme.font.family.titles1};
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    color: ${(props) => props.theme.color.footerLinkHover};
    text-shadow: 0 0 5px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 10px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 20px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 40px ${(props) => props.theme.color.footerLinkHoverShadow};
  }
`;

const FooterExternalLink = styled.a`
  color: ${(props) => props.theme.color.footerLink};
  font-family: ${(props) => props.theme.font.family.titles1};
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    color: ${(props) => props.theme.color.footerLinkHover};
    text-shadow: 0 0 5px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 10px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 20px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 40px ${(props) => props.theme.color.footerLinkHoverShadow};
  }
`;

const FooterWrapper = styled.footer`
  border-top: 1px solid ${(props) => props.theme.color.footer.border};
  color: ${(props) => props.theme.color.footer.border};
  background-color: ${(props) => props.theme.color.footer.background};
  padding: ${(props) => props.theme.padding.basic};
  padding-top: ${(props) => props.theme.padding.triple};

  ${(props) =>
    props.bgImg
      ? css`
          background: url(${props.bgImgSm || props.bgImg});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;

          @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
            background: url(${props.bgImg});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
        `
      : ''}

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding-left: 0;
    padding-right: 0;
  }

  .footer-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 0.9em;
  }

  a {
    font-family: ${(props) => props.theme.font.family.titles1};
    text-decoration: none;

    color: ${(props) => props.theme.color.white};
  }

  .contact-link {
    font-family: ${(props) => props.theme.font.family.titles1};
    color: ${(props) => props.theme.color.white};
    text-decoration: none;
    font-size: ${(props) => props.theme.font.size.contactLink};
    line-height: ${(props) => props.theme.font.lineHeight.contactLink};
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${(props) => props.theme.padding.basic};
  }

  .contact-wrapper {
    padding-bottom: ${(props) => props.theme.padding.double};
    flex-direction: column;

    & > * {
      padding-bottom: ${(props) => props.theme.padding.basic};
    }

    @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
      flex-direction: row;
    }
  }

  .icon-wrapper {
    padding-bottom: ${(props) => props.theme.padding.double};

    a:hover {
      filter: drop-shadow(0 0 0.75rem ${(props) => props.theme.color.footerLinkHoverShadow});
    }
  }

  .icon {
    margin-left: ${(props) => props.theme.padding.basic};
    margin-right: ${(props) => props.theme.padding.basic};
  }

  .copyright {
    font-size: ${(props) => props.theme.font.size.smallMobile};
    text-align: center;
    color: ${(props) => props.theme.color.footerLink};

    a {
      color: ${(props) => props.theme.color.footerLink};
      font-family: ${(props) => props.theme.font.family.titles1};
    }

    @media only screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
      font-size: ${(props) => props.theme.font.size.small};
    }
  }
`;

const FooterLogo = styled.img`
  width: 150px;
`;

const Footer = () => {
  return (
    <FooterWrapper role="contentinfo" bgImg={FooterBg} bgImgSm={FooterBgSm}>
      <div id="contact" className="footer-wrapper">
        <Row className="contact-wrapper">
          <Col size={1}>
            <Row direction="column">
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to="/#home-about">
                  ABOUT
                </FooterLinkHash>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to={`${paths.nfts}/#intro`}>
                  NFTs
                </FooterLinkHash>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to={`${paths.world}/#intro`}>
                  WORLD/STORY
                </FooterLinkHash>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to={`${paths.game}/#intro`}>
                  RACE GAME
                </FooterLinkHash>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to={`${paths.nfts}/#main-characters`}>
                  CHARACTERS
                </FooterLinkHash>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to="/#home-expansions">
                  IDEAS FOR EXPANSIONS
                </FooterLinkHash>
              </FooterLinkWrapper>
            </Row>
          </Col>
          <Col size={1}>
            <Row direction="column">
              <FooterLinkWrapper>
                <FooterExternalLink
                  href="https://opensea.io/collection/angels-of-aether"
                  target="_blank"
                  rel="noreferrer"
                  className="contact-link"
                >
                  ANGELS OPENSEA
                </FooterExternalLink>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterExternalLink
                  href="https://opensea.io/collection/guardians-of-aether"
                  target="_blank"
                  rel="noreferrer"
                  className="contact-link"
                >
                  GUARDIANS OPENSEA
                </FooterExternalLink>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to={`${paths.faq}/#faq`}>
                  FAQ
                </FooterLinkHash>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to={`${paths.privacy}/#privacy`}>
                  PRIVACY POLICY
                </FooterLinkHash>
              </FooterLinkWrapper>
              <FooterLinkWrapper>
                <FooterLinkHash className="contact-link" to={`${paths.terms}/#terms`}>
                  TERMS & CONDITIONS
                </FooterLinkHash>
              </FooterLinkWrapper>
            </Row>
          </Col>
        </Row>
        <div className="content icon-wrapper">
          <a href={`${externalLinks.twitter}`} target="_blank" rel="noreferrer">
            <Icon
              icon={IconTwitter}
              color={theme.color.footerLink}
              hoverColor={theme.color.footerLinkHover}
              className="icon"
            />
          </a>
          <a href={`${externalLinks.discord}`} target="_blank" rel="noreferrer">
            <Icon
              icon={IconDiscord}
              color={theme.color.footerLink}
              hoverColor={theme.color.footerLinkHover}
              className="icon"
            />
          </a>
          <a href={`${externalLinks.youtube}`} target="_blank" rel="noreferrer">
            <Icon
              icon={IconYoutube}
              color={theme.color.footerLink}
              hoverColor={theme.color.footerLinkHover}
              className="icon"
            />
          </a>
          <a
            href={`${externalLinks.openSeaAoA}`}
            title="Angels of Aether NFT Collection on OpenSea"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              icon={IconOpensea}
              color={theme.color.footerLink}
              hoverColor={theme.color.footerLinkHover}
              className="icon"
            />
          </a>
          <a
            href={`${externalLinks.openSeaGoA}`}
            title="Guardians of Aether NFT Collection on OpenSea"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              icon={IconOpensea}
              color={theme.color.footerLink}
              hoverColor={theme.color.footerLinkHover}
              className="icon"
            />
          </a>
        </div>
        <div className="copyright">
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <FooterLogo src={logo} />
          </div>
          copyright:
          <a href="/" title="Angels of Aether">
            {' Angels of Aether'}
          </a>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
