import React from 'react';
import styled from 'styled-components';
import { BoxSubTitle, BoxTitle, BoxWrapper } from './BasicBox';
import { BoxContentWrapper } from './IconBox';
import { getBoxDecorationsByConfig } from '../../helpers/decorations';
import StyledLink from '../link/StyledLink';
import defaultBgImg from '../../images/home/expansions/Expansions_card_bg_06.jpg';

const ImageBoxWrapper = styled.div``;

const StyledBoxWrapper = styled(BoxWrapper)`
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 200px;
  min-width: 300px;
`;

const ImageBoxContentWrapper = styled(BoxContentWrapper)`
  height: 120px;
  width: 220px;
  overflow: visible;

  p {
    text-align: left;
    text-shadow: 1px 1px 1px #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  position: absolute;
  bottom: 30px;
`;

const ImageBoxContent = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const BoxActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  position: absolute;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  background-image: linear-gradient(to left, rgba(20, 30, 37, 0.8), rgba(29, 54, 82, 1));

  width: 100%;
  padding: ${(props) => props.theme.padding.half};
  padding-right: 50px;

  a {
    text-align: right;
  }
`;

const ImageBox = ({
  title,
  subTitle,
  img = defaultBgImg,
  children,
  content = null,
  action = null,
  decorations = {},
  ...rest
}) => {
  return (
    <ImageBoxWrapper>
      <StyledBoxWrapper img={img} {...rest}>
        {getBoxDecorationsByConfig(decorations)}
        <BoxTitle>{title}</BoxTitle>
        <BoxSubTitle>{subTitle}</BoxSubTitle>
        <ImageBoxContentWrapper>
          {children}
          {content && <ImageBoxContent>{content}</ImageBoxContent>}
        </ImageBoxContentWrapper>
        {action && (
          <BoxActionsWrapper>
            <StyledLink to={action.linkTo}>{action.text}</StyledLink>
          </BoxActionsWrapper>
        )}
      </StyledBoxWrapper>
    </ImageBoxWrapper>
  );
};

export default ImageBox;
