import React from 'react';
import bgImg from '../../../images/world/gallery/bg/WorldGalleryBg.jpg';
import bgImgSm from '../../../images/world/gallery/bg/WorldGalleryBg-sm.jpg';
import GeneralGallerySection from '../GeneralGallerySection';
import BasicBox from '../../../components/box/BasicBox';
import Col from '../../../components/grid/Col';
import ReadMoreButton from '../../../components/button/ReadMoreButton';
import { paths } from '../../../routes/paths';

function importAll(r) {
  return r.keys().map(r);
}

const ArchitectureIms = importAll(
  require.context('../../../images/world/gallery/architecture/', false, /\.(png|jpe?g|svg)$/)
);
const ArchitectureThumbs = importAll(
  require.context('../../../images/world/gallery/architecture/thumbs/', false, /\.(png|jpe?g|svg)$/)
);
const RealmsIms = importAll(require.context('../../../images/world/gallery/realms/', false, /\.(png|jpe?g|svg)$/));
const RealmsThumbs = importAll(
  require.context('../../../images/world/gallery/realms/thumbs/', false, /\.(png|jpe?g|svg)$/)
);

const galleryRowHeight = 100;
const galleryImageMargin = 4;

const ArchitectureImages = ArchitectureIms.map((item, index) => ({
  src: item,
  thumbnail: ArchitectureThumbs[index],
  caption: 'Angels of Aether - Architecture'
}));

const RealmsImages = RealmsIms.map((item, index) => ({
  src: item,
  thumbnail: RealmsThumbs[index],
  caption: 'Angels of Aether - Realms'
}));

const imageGroups = [
  {
    title: 'Architecture',
    images: ArchitectureImages
  },
  {
    title: 'Realms',
    images: RealmsImages
  }
];

const customInfo = (
  <BasicBox
    fullPercWidth
    transparent
    subTitle="More angelic world environments can be seen in:"
    decorations={{ topRight: true, bottomLeft: true }}
    marginTop="40px"
  >
    <Col>
      <ReadMoreButton to={`${paths.game}/#gallery`}>Race Game Gallery</ReadMoreButton>
      <br />
    </Col>
  </BasicBox>
);

const NftsGallery = () => (
  <GeneralGallerySection
    title="World Gallery"
    customInfo={customInfo}
    bgImg={bgImg}
    bgImgSm={bgImgSm}
    imageGroups={imageGroups}
    galleryRowHeight={galleryRowHeight}
    galleryImageMargin={galleryImageMargin}
  />
);

export default NftsGallery;
