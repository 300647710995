import React from 'react';
import GeneralGallerySection from '../GeneralGallerySection';
import bgImg from '../../../images/raceGame/gallery/bg/GameGalleryBg.jpg';
import bgImgSm from '../../../images/raceGame/gallery/bg/GameGalleryBg-sm.jpg';

const galleryRowHeight = 100;
const galleryImageMargin = 4;

function importAll(r) {
  return r.keys().map(r);
}

const ConceptIms = importAll(
  require.context('../../../images/raceGame/gallery/concepts/', false, /\.(png|jpe?g|svg)$/)
);
const ConceptThumbs = importAll(
  require.context('../../../images/raceGame/gallery/concepts/thumbs/', false, /\.(png|jpe?g|svg)$/)
);

const RaceIms = importAll(require.context('../../../images/raceGame/gallery/race/', false, /\.(png|jpe?g|svg)$/));
const RaceThumbs = importAll(
  require.context('../../../images/raceGame/gallery/race/thumbs/', false, /\.(png|jpe?g|svg)$/)
);
const EnvironmentsIms = importAll(
  require.context('../../../images/raceGame/gallery/environments/', false, /\.(png|jpe?g|svg)$/)
);
const EnvironmentsThumbs = importAll(
  require.context('../../../images/raceGame/gallery/environments/thumbs/', false, /\.(png|jpe?g|svg)$/)
);

const ConceptImages = ConceptIms.map((item, index) => {
  return {
    src: item,
    thumbnail: ConceptThumbs[index],
    caption: 'Angels of Aether'
  };
});

const RaceImages = RaceIms.map((item, index) => {
  return {
    src: item,
    thumbnail: RaceThumbs[index],
    caption: 'Angels of Aether'
  };
});

const EnvironmentsImages = EnvironmentsIms.map((item, index) => {
  return {
    src: item,
    thumbnail: EnvironmentsThumbs[index],
    caption: 'Angels of Aether'
  };
});

const imageGroups = [
  {
    title: 'Concepts',
    images: ConceptImages
  },
  {
    title: 'Race Gameplay',
    images: RaceImages
  },
  {
    title: 'Environments',
    images: EnvironmentsImages
  }
];

const GameGallery = () => (
  <GeneralGallerySection
    title="Race Game Gallery"
    bgImg={bgImg}
    bgImgSm={bgImgSm}
    imageGroups={imageGroups}
    galleryRowHeight={galleryRowHeight}
    galleryImageMargin={galleryImageMargin}
  />
);

export default GameGallery;
