import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.hr`
  height: 1px;
  border: 0;
  margin: ${(props) => props.theme.padding.half} 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
`;

const Divider = () => {
  return <StyledDivider />;
};

export default Divider;
