import React from 'react';
import styled from 'styled-components';
import Section from '../../../components/section/Section';
import bgImg from '../../../images/home/AngelsOfAether_LandingBg.jpg';
import bgImgSm from '../../../images/home/AngelsOfAether_LandingBg-sm.jpg';
import BasicBox from '../../../components/box/BasicBox';
import logo from '../../../images/AV_logo-m.png';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import IntroBgSm from '../../../images/raceGame/RaceIntroBg-sm.jpg';

const MobileLogo = styled.img`
  position: absolute;
  top: 50px;
  width: 55vw;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const IntroBottomBox = styled(BasicBox)`
  padding: 14px;
  user-select: none;

  p {
    font-size: 24px;
    margin
  }
`;

const IntroSection = () => {
  return (
    <Section fullHeight bgImg={bgImg} bgImgSm={bgImgSm} id="home-intro">
      <MobileLogo src={logo} />
      <SectionSwitcher to="/#home-about" text="More" showAlways />
    </Section>
  );
};

export default IntroSection;
