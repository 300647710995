import React from 'react';
import CardBg01 from '../../../images/home/expansions/Expansions_card_bg_01.jpg';
import CardBg02 from '../../../images/home/expansions/Expansions_card_bg_02.jpg';
import CardBg03 from '../../../images/home/expansions/Expansions_card_bg_03.jpg';
import CardBg04 from '../../../images/home/expansions/Expansions_card_bg_04.jpg';
import CardBg05 from '../../../images/home/expansions/Expansions_card_bg_05.jpg';
import CardBg06 from '../../../images/home/expansions/Expansions_card_bg_06.jpg';
import bgImg from '../../../images/WingBg04.jpg';
import bgImgSm from '../../../images/WingBg04-sm.jpg';
import CardsSection from '../CardsSection';

const boxes = [
  {
    title: 'Race Tournaments',
    subTitle: 'First UE5 Game',
    content: (
      <p>
        Imagine Angels competing in an exciting flying tournament, taking place on an exotic island full of thrilling
        challenges.
      </p>
    ),
    img: CardBg01,
    decorations: { topLeft: true }
  },
  {
    title: 'Metaverse Experience',
    subTitle: 'in Angelic Realms',
    content: (
      <p>
        Would be awesome to walk/fly with angelic avatars through vast mythical lands, joining friends on multiple
        advantures.
      </p>
    ),
    img: CardBg02,
    decorations: { topRight: true }
  },
  {
    title: 'Avatar Customization',
    subTitle: 'Upgradable NFTs',
    content: (
      <p>
        Being able to customize your NFT character is an exciting possibility which brings more variation & action to an
        NFT collection.
      </p>
    ),
    img: CardBg03,
    decorations: { bottomLeft: true, topRight: true, topLeft: true }
  },
  {
    title: 'Art Exhibitions',
    subTitle: 'in NFT Galleries',
    content: (
      <p>
        The angels have natural sense for art and beauty, so to host various art exhibitions in gorgeous NFT galleries
        is for them a very common thing.
      </p>
    ),
    img: CardBg04,
    decorations: { bottomRight: true, topLeft: true }
  },
  {
    title: 'Owning Real Estate',
    subTitle: 'in the angelic world',
    content: (
      <p>
        With metaverse experience comes hand in hand the possibility to own a beautiful architecturally unique building
        of various characteristics.
      </p>
    ),
    img: CardBg05,
    decorations: { bottomLeft: true }
  },
  {
    title: 'Adventure Quests',
    subTitle: 'into mystical places',
    content: (
      <p>
        It is good to have a growing number of miscellaneous exciting adventure quests available in a game, to maintain
        the fun & diversity of activities.
      </p>
    ),
    img: CardBg06,
    decorations: { bottomRight: true, bottomLeft: true, topRight: true }
  }
];

const IdeasForExpansions = () => (
  <CardsSection
    id="home-expansions"
    linkTo="#gallery"
    linkText="General Gallery"
    boxes={boxes}
    title="Ideas for possible Expansions"
    bgImg={bgImg}
    bgImgSm={bgImgSm}
  />
);

export default IdeasForExpansions;
