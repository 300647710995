import React from 'react';
import Section from '../components/section/Section';
import SectionTitle from '../components/section/SectionTitle';
import bgImg from '../images/FAQBg.jpg';
import BasicBox from '../components/box/BasicBox';
import { externalLinks } from '../staticData/LinkNodes';

const items = [
  {
    title: 'Non-Fungible Token Standard',
    description: (
      <>
        <p>ERC-721</p>
      </>
    )
  },
  {
    title: 'Blockchain/Network',
    description: (
      <>
        <p>ETH</p>
      </>
    )
  },
  {
    title: 'Total amount of “Angels of Aether” Non-Fungible Tokens',
    description: (
      <>
        <p>5123</p>
      </>
    )
  },
  {
    title: 'Total amount of “Guardians of Aether” Non-Fungible Tokens',
    description: (
      <>
        <p>3500</p>
      </>
    )
  },
  {
    title: 'How can I purchase “Angels of Aether” NFT’s?',
    description: (
      <>
        <p>
          The most simple method is by downloading and installing the Metamask extension in your browser, and then
          connecting Metamask to{' '}
          <a href={`${externalLinks.openSeaAoA}`} target="_blank" rel="noreferrer">
            {`${externalLinks.openSeaAoA}`}
          </a>
          . Ensure you have enough ETH to cover the costs of both - Angels, as well as any gas fees associated with the
          transaction.
        </p>
      </>
    )
  },
  {
    title: 'How can I purchase “Guardians of Aether” NFT’s?',
    description: (
      <>
        <p>
          The most simple method is by downloading and installing the Metamask extension in your browser, and then
          connecting Metamask to{' '}
          <a href={`${externalLinks.openSeaGoA}`} target="_blank" rel="noreferrer">
            {`${externalLinks.openSeaGoA}`}
          </a>
          . Ensure you have enough ETH to cover the costs of both - Guardians, as well as any gas fees associated with
          the transaction.
        </p>
      </>
    )
  },
  {
    title: 'Where can I find out more about Angels of Aether?',
    description: (
      <>
        <p>
          Website:{' '}
          <a href={`${externalLinks.website}`} target="_blank" rel="noreferrer">
            {`${externalLinks.website}`}
          </a>
        </p>
        <p>
          Twitter:{' '}
          <a href={`${externalLinks.twitter}`} target="_blank" rel="noreferrer">
            {`${externalLinks.twitter}`}
          </a>
        </p>
        <p>
          Discord:{' '}
          <a href={`${externalLinks.discord}`} target="_blank" rel="noreferrer">
            {`${externalLinks.discord}`}
          </a>
        </p>
        <p>
          Instagram:{' '}
          <a href={`${externalLinks.instagram}`} target="_blank" rel="noreferrer">
            {`${externalLinks.instagram}`}
          </a>
        </p>
        <p>
          Youtube:{' '}
          <a href={`${externalLinks.youtube}`} target="_blank" rel="noreferrer">
            {`${externalLinks.youtube}`}
          </a>
        </p>
        <p>
          Opensea AoA:{' '}
          <a href={`${externalLinks.openSeaAoA}`} target="_blank" rel="noreferrer">
            {`${externalLinks.openSeaAoA}`}
          </a>
        </p>
        <p>
          Opensea GoA:{' '}
          <a href={`${externalLinks.openSeaGoA}`} target="_blank" rel="noreferrer">
            {`${externalLinks.openSeaGoA}`}
          </a>
        </p>
      </>
    )
  }
];

const FAQPage = () => {
  return (
    <Section id="faq" fullHeight fullContainerWidth bgImg={bgImg} bgStatic paddingTop="200px" paddingBottom="140px">
      <SectionTitle decorated={false}>Frequently Asked Questions</SectionTitle>
      {items.map((item) => (
        <BasicBox
          fullPercWidth
          transparent
          title={item.title}
          subTitle={item.subtitle || ''}
          decorations={item.decoration || { topLeft: true, bottomRight: true }}
        >
          {item.description}
        </BasicBox>
      ))}
    </Section>
  );
};

export default FAQPage;
