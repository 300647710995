import React from 'react';
import styled from 'styled-components';
import Gallery from 'react-grid-gallery';
import SectionsGroup from '../SectionsGroup';
import BasicBox from '../../../components/box/BasicBox';
import AngelsBg from '../../../images/nfts/bg/AngelsBg.jpg';
import AngelsBgSm from '../../../images/nfts/bg/AngelsBg-sm.jpg';
import GuardiansBg from '../../../images/nfts/bg/GuardiansPortraitBg.jpg';
import GuardiansBgSm from '../../../images/nfts/bg/GuardiansPortraitBg-sm.jpg';
import ArcaniaAndAurisBg from '../../../images/nfts/bg/ArcaniaAndAurisBg.jpg';
import ArcaniaAndAurisBgSm from '../../../images/nfts/bg/ArcaniaAndAurisBg-sm.jpg';
import HighAngelBg01 from '../../../images/nfts/highAngels/Aera.jpg';
import HighAngelBg01Sm from '../../../images/nfts/highAngels/Aera-sm.jpg';
import HighAngelBg02 from '../../../images/nfts/highAngels/Aesherah.jpg';
import HighAngelBg02Sm from '../../../images/nfts/highAngels/Aesherah-sm.jpg';
import HighAngelBg03 from '../../../images/nfts/highAngels/Joy.jpg';
import HighAngelBg03Sm from '../../../images/nfts/highAngels/Joy-sm.jpg';
import HighAngelBg04 from '../../../images/nfts/highAngels/Faith.jpg';
import HighAngelBg04Sm from '../../../images/nfts/highAngels/Faith-sm.jpg';
import HighAngelBg05 from '../../../images/nfts/highAngels/Keah.jpg';
import HighAngelBg05Sm from '../../../images/nfts/highAngels/Keah-sm.jpg';
import HighAngelBg06 from '../../../images/nfts/highAngels/Kahili.jpg';
import HighAngelBg06Sm from '../../../images/nfts/highAngels/Kahili-sm.jpg';
import HighAngelBg07 from '../../../images/nfts/highAngels/Shanali.jpg';
import HighAngelBg07Sm from '../../../images/nfts/highAngels/Shanali-sm.jpg';
import HighAngelBg08 from '../../../images/nfts/highAngels/Ravani.jpg';
import HighAngelBg08Sm from '../../../images/nfts/highAngels/Ravani-sm.jpg';
import HighAngelBg09 from '../../../images/nfts/highAngels/LaWei.jpg';
import HighAngelBg09Sm from '../../../images/nfts/highAngels/LaWei-sm.jpg';
import HighAngelBg10 from '../../../images/nfts/highAngels/Ehrya.jpg';
import HighAngelBg10Sm from '../../../images/nfts/highAngels/Ehrya-sm.jpg';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import SectionTitle from '../../../components/section/SectionTitle';
import ReadMoreButton from '../../../components/button/ReadMoreButton';
import AnimatedAngelRavani from '../../../components/angels/AnimatedRavani';
import AnimatedAngelLaWei from '../../../components/angels/AnimatedLaWei';
import AnimaAnimatedEhrya from '../../../components/angels/AnimatedEhrya';
import { paths } from '../../../routes/paths';

const CharacterSubtitle = styled.h4`
  margin: 0 !important;
  margin-bottom: 1px !important;
`;

const infoRows = [
  {
    centerContent: (
      <>
        <SectionTitle decorated={false}>Angels</SectionTitle>
        <BasicBox transparent>
          <ul>
            <li>The core Angels of Aether NFT collection consists of 5123 unique angelic characters.</li>
            <li>
              It is the primary angelic legion of brave celestial beings, who came to elevate and protect the
              creativity, artistic expression and high vibration on Earth. Each one is unique and has her own specific
              traits and abilities. There are 11 special one-of-one NFTs among them - 10 rare High Angels and the one
              and only ultra rare Queen of the Angels – Arcania.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#guardians`} text="" />
        </BasicBox>
      </>
    ),
    id: 'angels',
    linkTo: `${paths.nfts}/#guardians`,
    linkDark: true,
    linkText: 'Guardians',
    bg: `url(${AngelsBg})`,
    bgSm: `url(${AngelsBgSm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <SectionTitle decorated={false}>Guardians</SectionTitle>
        <BasicBox transparent>
          <ul>
            <li>
              The second NFT collection – Guardians of Aether came to life to partner with the Angels and create a
              specific bond between them. The Guardians are the SoulMates of the Angels and came to protect them and
              enhance their powers.
            </li>
            <li>
              The overall Guardian collection consists of 3500 unique celestial NFT characters with specific traits.
              There are 10 rare High Guardians and the one and only one-of-one, ultra rare Lord of the Guardians –
              Auris.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#main-characters`} text="" />
        </BasicBox>
      </>
    ),
    id: 'guardians',
    linkTo: `${paths.nfts}/#main-characters`,
    linkText: 'Main Characters',
    bg: `url(${GuardiansBg})`,
    bgSm: `url(${GuardiansBgSm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <SectionTitle decorated={false}>Main Characters</SectionTitle>
        <BasicBox transparent>
          <ul>
            <li>
              In addition to specific story characters among the regular angels, there are 10 High Angels and 10 High
              Guardians who play a unique role in the overall story. The highest among all the angels, stands Arcania –
              the Queen of Angels of Aether. She is the ultimate leader and provider of the highest Divine Light. But
              she is not alone, she has Auris – the Lord of the Guardians - standing at her side, providing all the Help
              and Love he can muster.
            </li>
            <li>
              <ReadMoreButton to={`${paths.world}/#story`}>Learn more about the Story</ReadMoreButton>
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#aera`} text="" />
        </BasicBox>
      </>
    ),
    id: 'main-characters',
    linkTo: `${paths.nfts}/#aera`,
    linkText: 'Aera',
    bg: `url(${ArcaniaAndAurisBg})`,
    bgSm: `url(${ArcaniaAndAurisBgSm})`,
    bgPosition: 'center'
  },
  {
    centerContent: (
      <>
        <h1>Aera</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Order</CharacterSubtitle>
          <ul>
            <li>
              The Divine Powers guarantee Freedom and possibility to Express in a myriad of ways, but they also want to
              ensure, that an expression of one will not annihilate another. And that is Aera’s Responsibility – to
              secure Order of Universal Laws. That’s why her Presence is very important in chaotic places and in
              disturbing situations of Universal significance. She is very confident, calm and has extraordinary sense
              for justice. Aera wields the power to bring order to any situation or place.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#aesherah`} text="" />
        </BasicBox>
      </>
    ),
    id: 'aera',
    linkTo: `${paths.nfts}/#aesherah`,
    linkText: 'Aesherah',
    bg: `url(${HighAngelBg01})`,
    bgSm: `url(${HighAngelBg01Sm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <h1>Aesherah</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Life</CharacterSubtitle>
          <ul>
            <li>
              Aesherah rules over Life and Death. She can give Life, or take it in a blink of an eye. She has many names
              in various cultures - Bringer of Life, Life Taker, Death, The Inevitable, … But her major role is to
              Protect All Life in Aether-Verse. That is not an easy task, because it is necessary to let the law of the
              Free Will intact, while not allowing one aggressive group to destroy another peaceful cluster of life. But
              she is the right one to distinguish the silver lining.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#joy`} text="" />
        </BasicBox>
      </>
    ),
    id: 'aesherah',
    linkTo: `${paths.nfts}/#joy`,
    linkText: 'Joy',
    bg: `url(${HighAngelBg02})`,
    bgSm: `url(${HighAngelBg02Sm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <h1>Joy</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Joy</CharacterSubtitle>
          <ul>
            <li>
              Imagine a feeling, when your Heart starts to lighten up, all the tension of your body drops, you enjoy the
              Present Moment and you cannot tell how, but … you just smile for no reason. You feel a positive tingling
              sensation spreading through your whole body and know that every problem has a solution. That’s how you
              feel when Joy approaches. She inspires people to Open their Hearts and let joy brighten their lives. And
              since Joy is a very helpful companion for every creative person – it is necessary for this High Angel to
              participate on this mission to Earth.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#faith`} text="" />
        </BasicBox>
      </>
    ),
    id: 'joy',
    linkTo: `${paths.nfts}/#faith`,
    linkText: 'Faith',
    bg: `url(${HighAngelBg03})`,
    bgSm: `url(${HighAngelBg03Sm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <h1>Faith</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Faith</CharacterSubtitle>
          <ul>
            <li>
              This powerful High Angel was one of the first Angels that stood beside Arcania in the Beginning of Time.
              During the Angelic mission to Earth, Faith’s responsibility is to strengthen the Confidence and Faith of
              all the people and Bless them with Trust in the Divine. When there is desolation and despair, the High
              Angel of Faith comes to aid. Her Light is so bright, that it dissolves any gloomy thoughts. A refreshing
              sensation of freedom can be felt, when she is near. She also carries a Sacred Pink Lotus, that holds the
              Golden Light of Divine Faith.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#keah`} text="" />
        </BasicBox>
      </>
    ),
    id: 'faith',
    linkTo: `${paths.nfts}/#keah`,
    linkText: 'Keah',
    bg: `url(${HighAngelBg04})`,
    bgSm: `url(${HighAngelBg04Sm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <h1>Keah</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Flow</CharacterSubtitle>
          <ul>
            <li>
              To allow Heaven fill our lives means to let go of any fear and doubt and open to the Divine Flow, to
              surrender to the Divine Will. In this case surrender does not mean to loose, it means to allow God to
              activate the best possible version of our-selves. It also does not mean inaction (that is laziness). One
              should take the right action only at the right time, guided be the God’s Flow. Keah helps people to relax
              and to let go of too much ego tension; helps them to blossom by opening their heart to the Divine Truth;
              embrace their Real True Authenticity; allowing them to surrender to their own Higher Version of
              Them-Selves.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#kahili`} text="" />
        </BasicBox>
      </>
    ),
    id: 'keah',
    linkTo: `${paths.nfts}/#kahili`,
    linkText: 'Kahili',
    bg: `url(${HighAngelBg05})`,
    bgSm: `url(${HighAngelBg05Sm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <h1>Kahili</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Passion</CharacterSubtitle>
          <ul>
            <li>
              Kahili reminds us to do everything with our full Potential, with blazing Passion, fully immersed in the
              present moment. We have been given a certain amount of time here on Earth, so do not waste it – enjoy
              every moment, savor any situation, do everything with Passion and give over to intuition and our True
              Authentic Nature. Passion relates to any aspect of our lives. Let’s ignite our Inner Fire. Let’s live with
              Sacred Zest for Life. Stop playing a survival role in this life - let’s LIVE passionately and abundantly,
              embodying our full Divine Potential!
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#shanali`} text="" />
        </BasicBox>
      </>
    ),
    id: 'kahili',
    linkTo: `${paths.nfts}/#shanali`,
    linkText: 'Shanali',
    bg: `url(${HighAngelBg06})`,
    bgSm: `url(${HighAngelBg06Sm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <h1>Shanali</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Peace</CharacterSubtitle>
          <ul>
            <li>
              Shanali is the perfect Embodiment of Divine Peace. She is the first sister of Arcania. On the mission to
              Earth she will hold the Space and Vibration of the Inner Peace of every individual on the planet. Because
              the overall level of Peace on Earth is just a reflection of Inner Peace that every person holds within.
              Shanali is able to dissolve any chaotic vibes and disharmonies and restore the pure Calmness of the
              Divine.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#ravani`} text="" />
        </BasicBox>
      </>
    ),
    id: 'shanali',
    linkTo: `${paths.nfts}/#ravani`,
    linkText: 'Ravani',
    bg: `url(${HighAngelBg07})`,
    bgSm: `url(${HighAngelBg07Sm})`,
    bgPosition: 'center',
    bgStatic: true
  },
  {
    centerContent: (
      <>
        <h1>Ravani</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Creativity and Abundance</CharacterSubtitle>
          <ul>
            <li>
              Her presence awakens True Artist in anybody, filling the space with Pure Divine Inspiration and Creative
              Passion. She is the patron of all artists and creatives, that’s why she is one of the key powers to
              support the creative vibe on Earth. Ravani also reminds us of the infiniteness of the Universe,
              overflowing us with endless Abundance. After being touched by her blessing, we can experience a feeling of
              sacred fulfillment, a state that all our needs are met in every aspect of life, including Love, Joy,
              Happiness, Creativity, Relations, any Situations, Lessons, ...etc. The next level is to realize – that
              everything already IS, we just need to align with It, become It.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#lawei`} text="" />
        </BasicBox>
      </>
    ),
    deeperContent: <AnimatedAngelRavani size={400} sizeLg={500} sizeXl={600} />,
    id: 'ravani',
    linkTo: `${paths.nfts}/#lawei`,
    linkText: 'La Wei',
    bg: `url(${HighAngelBg08})`,
    bgSm: `url(${HighAngelBg08Sm})`,
    bgPosition: 'center'
  },
  {
    centerContent: (
      <>
        <h1>La Wei</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of Love</CharacterSubtitle>
          <ul>
            <li>
              La’Wei, the second sister of Arcania, is the personification of Divine Love – thus the strongest Power in
              the whole Universe. Her Light dissolves anything that deviates the Divine True Pattern. But her nature is
              not aggressive, nor compelling, rather she is like water – slowly and peacefully flowing, accepting all
              there is, but with silent perseverance remaining in her True Essence – thus inevitably changing everything
              around to her immutable vibration, drop by drop. Genuine Divine Love is the True Core Essence in every
              heart there is. That is why La’Wei’s mission is to help opening each and every single heart to its
              Authentic Pattern.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#ehrya`} text="" />
        </BasicBox>
      </>
    ),
    deeperContent: <AnimatedAngelLaWei size={400} sizeLg={500} sizeXl={600} />,
    id: 'lawei',
    linkTo: `${paths.nfts}/#ehrya`,
    linkText: 'Ehrya',
    bg: `url(${HighAngelBg09})`,
    bgSm: `url(${HighAngelBg09Sm})`,
    bgPosition: 'center'
  },
  {
    centerContent: (
      <>
        <h1>Ehrya</h1>
        <BasicBox transparent>
          <CharacterSubtitle>High Angel of elements</CharacterSubtitle>
          <ul>
            <li>
              Earth, Water, Fire, Air, Metal, Aether and Spirit. These are the 7 basic elements in Aether-Verse. They
              were created in the beginning, that’s why a direct descendant of Guardians of Aether was selected to take
              care of them. Ehrya has the ability to bend the Reality, reform the structure of sacred key forms and
              protect the morphological patterns of all the realms of the Aether-Verse. She has the responsibility to
              hold the objective reality in compact harmonious form.
            </li>
          </ul>
          <SectionSwitcher smallRelative to={`${paths.nfts}/#gallery`} text="" />
        </BasicBox>
      </>
    ),
    deeperContent: <AnimaAnimatedEhrya size={400} sizeLg={500} sizeXl={600} />,
    id: 'ehrya',
    linkTo: `${paths.nfts}/#gallery`,
    linkText: 'NFT Gallery',
    bg: `url(${HighAngelBg10})`,
    bgSm: `url(${HighAngelBg10Sm})`,
    bgPosition: 'center'
  }
];

const HomeSections = () => <SectionsGroup id="section" infoRows={infoRows} />;

export default HomeSections;
