import theme from '../styles/theme';

export const getGradientStyles = (isTransparent = false, transparency = 'CC') => `
  background: ${theme.color.primaryLight};
  background: linear-gradient(
    17deg,
    ${theme.color.primaryLight}${isTransparent ? transparency : ''} 0%,
    ${theme.color.primaryDark}${isTransparent ? transparency : ''} 36%
  );
`;
