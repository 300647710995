import { flatten, filter } from 'rambda';
import { paths } from '../routes/paths';

export const headerLinks = [
  {
    title: 'Home',
    linkTo: `${paths.home}#home-intro`,
    links: [
      {
        title: 'About',
        linkTo: '/#home-about'
      },
      {
        title: 'NFTs',
        linkTo: '/#home-nfts',
        notForMobile: true
      },
      {
        title: 'World',
        linkTo: '/#home-world',
        notForMobile: true
      },
      {
        title: 'Race Game',
        linkTo: '/#home-game',
        notForMobile: true
      },
      {
        title: 'Ideas for Expansions',
        linkTo: '/#home-expansions'
      },
      {
        title: 'General Gallery',
        linkTo: '/#gallery'
      }
    ]
  },
  {
    title: 'NFTs',
    linkTo: `${paths.nfts}/#intro`,
    links: [
      {
        title: 'About',
        linkTo: `${paths.nfts}/#intro`,
        notForMobile: true
      },
      {
        title: 'Angel NFTs',
        linkTo: `${paths.nfts}/#angels`
      },
      {
        title: 'Guardian NFTs',
        linkTo: `${paths.nfts}/#guardians`
      },
      {
        title: 'Main Characters',
        linkTo: `${paths.nfts}/#main-characters`
      },
      {
        title: 'NFT Gallery',
        linkTo: `${paths.nfts}/#gallery`
      }
    ]
  },
  {
    title: 'World',
    linkTo: `${paths.world}/#intro`,
    links: [
      {
        title: 'About',
        linkTo: `${paths.world}/#intro`,
        notForMobile: true
      },
      {
        title: 'Biomes',
        linkTo: `${paths.world}/#biomes`
      },
      {
        title: 'Story',
        linkTo: `${paths.world}/#story`
      },
      {
        title: 'World Gallery',
        linkTo: `${paths.world}/#gallery`
      }
    ]
  },
  {
    title: 'Race Game',
    linkTo: `${paths.game}/#intro`,
    links: [
      {
        title: 'About',
        linkTo: `${paths.game}/#intro`,
        notForMobile: true
      },
      {
        title: 'Game Development',
        linkTo: `${paths.game}/#development`
      },
      {
        title: 'Island Story',
        linkTo: `${paths.game}/#story`
      },
      {
        title: 'Map Locations',
        linkTo: `${paths.game}/#locations`
      },
      {
        title: 'Race Game Gallery',
        linkTo: `${paths.game}/#gallery`
      }
    ]
  }
];

export const mobileLinks = flatten(
  headerLinks.map((item, index) => {
    const { title, linkTo, links } = item;
    const mobileLinks = filter((item) => !item.notForMobile, links);

    return [
      { title, linkTo },
      ...mobileLinks.map((link) => {
        return { ...link, sublink: true };
      })
    ];
  })
);
