import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

const LinkComponent = styled(HashLink)`
  color: ${(props) => props.theme.color.footerLink};
  text-decoration: none;
  font-family: ${(props) => props.theme.font.family.titles1};
  font-size: ${(props) => props.theme.font.size.styledHashLink};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const scrollWidthOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -40;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const StyledHashLink = ({ children, ...rest }) => (
  <LinkComponent smooth scroll={scrollWidthOffset} {...rest}>
    {children}
  </LinkComponent>
);

export default StyledHashLink;
