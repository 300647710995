import React from 'react';
import { Controller } from 'react-scrollmagic';
import IntroSection from './sections/game/GameIntro';
import MapLocations from './sections/game/MapLocations';
import GameSections from './sections/game/GameSections';
import GameGallery from './sections/game/GameGallery';

const GamePage = () => {
  return (
    <Controller>
      <IntroSection />
      <GameSections />
      <MapLocations />
      <GameGallery />
    </Controller>
  );
};

export default GamePage;
