import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkWrapper = styled.div`
  text-align: center;
`;

const LinkComponent = styled(Link)`
  text-decoration: none;
  color: white;
  &:hover {
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #efdcba;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const StyledLink = ({ children, to, ...rest }) => (
  <LinkWrapper {...rest}>
    <LinkComponent to={to}>{children}</LinkComponent>
  </LinkWrapper>
);

export default StyledLink;
