import React from 'react';
import Section from '../../../components/section/Section';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import { daySunRotation as video } from '../../../staticData/videoLinks';
import SectionTitle from '../../../components/section/SectionTitle';
import IntroBox from '../../../components/box/IntroBox';
import { paths } from '../../../routes/paths';
import IntroBg from '../../../images/world/WorldIntroBg.jpg';
import IntroBgSm from '../../../images/world/WorldIntroBg-sm.jpg';

const WorldIntro = () => {
  return (
    <Section id="intro" fullHeight fullContainerHeight video={video} bgImg={IntroBg} bgImgSm={IntroBgSm}>
      <SectionTitle decorated={false}>World / Story</SectionTitle>
      <IntroBox transparent>
        The angelic world is a magical celestial realm of countless posibilities. The first Divine Thought, which formed
        its Core was - Creativity. The angelic dimension consist of omnifarious biomes with unique peculiarities, each
        characteristic to a specifit Element and specific Spiritual Lectures. In the heart of the angelic dimension,
        high above the ground levels of mysthical lands, a cluster of mysthical clouds floats in the air - it forms the
        base of the floating Crystal City, the capital of the realms and the home base of the Angels of Aether. And
        there, in the center of the city, in the crystal palace, Arcania, the Queen of the Angels, sits on her throne.
        <SectionSwitcher smallRelative to={`${paths.world}/#biomes`} text="" />
      </IntroBox>
      <SectionSwitcher to={`${paths.world}/#biomes`} />
    </Section>
  );
};

export default WorldIntro;
