import React from 'react';
import styled from 'styled-components';
import decoration from '../../images/decorations/button-decoration.png';

const StyledTitleDecoration = styled.img`
  height: 48px;
  width: 45px;
  z-index: 10;
  position: absolute;
  top: 18px;
`;

const DecorationWrapper = styled.div`
  &:before {
    content: '';
    display: block;
    width: 130px;
    height: 1px;
    position: absolute;

    left: -200px;
    top: 50%;
    background-image: linear-gradient(to right, transparent, white);
  }

  &:after {
    content: '';
    display: block;
    width: 130px;
    height: 1px;
    position: absolute;

    right: -200px;
    top: 50%;
    background-image: linear-gradient(to left, transparent, white);
  }
`;

export const SectionTitleDecoration = (props) => {
  return (
    <DecorationWrapper>
      <StyledTitleDecoration src={decoration} {...props} />
    </DecorationWrapper>
  );
};

export const TitleDecorationLeft = styled(SectionTitleDecoration)`
  left: -80px;
`;

export const TitleDecorationRight = styled(SectionTitleDecoration)`
  transform: rotate(180deg);
  right: -80px;
`;
