import React from 'react';
import styled from 'styled-components';
import Gallery from 'react-grid-gallery';
import Section from '../../components/section/Section';
import SectionTitle from '../../components/section/SectionTitle';

const GalleryWrapper = styled.div`
  display: block;
  min-height: 100px;
  width: 100%;
  margin: ${(props) => props.theme.padding.triple} 0;
  margin-top: ${(props) => props.theme.padding.basic};
  margin-bottom: ${(props) => props.theme.padding.basic};

  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;

const GeneralGallerySection = ({
  title,
  bgImg,
  bgImgSm,
  imageGroups,
  galleryRowHeight,
  galleryImageMargin,
  noGroupTitles,
  customInfo = undefined
}) => {
  return (
    <Section fullHeight bgImg={bgImg} bgImgSm={bgImgSm} id="gallery" bgStatic paddingTop="100px" paddingBottom="100px">
      <SectionTitle decorated={false} shinyShadow>
        {title}
      </SectionTitle>
      {imageGroups.map((imageGroup) => (
        <GalleryWrapper key={`gallery-${imageGroup.title}`}>
          {imageGroup.title && !noGroupTitles && <h3>{imageGroup.title}</h3>}
          <Gallery
            images={imageGroup.images}
            rowHeight={galleryRowHeight}
            margin={galleryImageMargin}
            enableImageSelection={false}
            preloadNextImage
          />
        </GalleryWrapper>
      ))}

      {customInfo}
    </Section>
  );
};

export default GeneralGallerySection;
