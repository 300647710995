import React from 'react';
import styled from 'styled-components';

const StyledApp = styled.div`
  background-color: ${(props) => props.theme.color.defaultBg};
  font-family: ${(props) => props.theme.font.family.defaultFamily};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;

  html > iframe {
    display: none;
  }

  section {
    overflow: hidden !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: fit-content;
    text-align: center;
    filter: drop-shadow(0 0 0.75rem black);
    color: ${(props) => props.theme.color.defaultTitle};
    background: -webkit-linear-gradient(
      ${(props) => props.theme.color.secondaryLight},
      ${(props) => props.theme.color.secondaryDark}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    font-size: ${(props) => props.theme.font.size.title1};
    font-family: ${(props) => props.theme.font.family.titles1};
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.title2};
    font-family: ${(props) => props.theme.font.family.titles2};
  }

  h3 {
    font-size: ${(props) => props.theme.font.size.title3};
    font-family: ${(props) => props.theme.font.family.titles3};
  }

  h4 {
    font-size: ${(props) => props.theme.font.size.title4};
    font-family: ${(props) => props.theme.font.family.titles4};
  }

  h5 {
    font-size: ${(props) => props.theme.font.size.title5};
    font-family: ${(props) => props.theme.font.family.titles5};
  }

  h6 {
    font-size: ${(props) => props.theme.font.size.title6};
    font-family: ${(props) => props.theme.font.family.titles6};
  }

  p {
    font-family: ${(props) => props.theme.font.family.paragraphs};
    text-align: left;
    color: ${(props) => props.theme.color.defaultParagraph};
    font-size: ${(props) => props.theme.font.size.defaultText};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    p {
      text-align: left;
    }
  }
`;

export default StyledApp;
