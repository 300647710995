import React from 'react';
import Section from '../../../components/section/Section';
import SectionSwitcher from '../../../components/sectionSwither/SectionSwitcher';
import SectionTitle from '../../../components/section/SectionTitle';
import IntroBox from '../../../components/box/IntroBox';
import bgImg from '../../../images/nfts/bg/NFTsIntroBg.jpg';
import bgImgSm from '../../../images/nfts/bg/NFTsIntroBg-sm.jpg';
import { paths } from '../../../routes/paths';

const NFTsIntro = () => {
  return (
    <Section id="intro" fullHeight fullContainerHeight bgImg={bgImg} bgImgSm={bgImgSm} justifyDown>
      <SectionTitle decorated={false}>NFTs</SectionTitle>
      <IntroBox transparent>
        The beautiful angelic NFT collection is the main core of the whole project. It started with the Angels - each
        one is a unique combination of specific traits and characteristics, portraing fairness and artistic soul. After
        the angels were finetuned, the second NFT collection came to life – the Guardians – the Soulmates of the Angels
        - who, by comming to the presence of the Angels, upgraded them to higher level, which can be recognized by a new
        trait called Seal of Love (the aura behind the head of the angels).
        <SectionSwitcher smallRelative to={`${paths.nfts}/#angels`} text="Angels" />
      </IntroBox>
      <SectionSwitcher dark to={`${paths.nfts}/#angels`} />
    </Section>
  );
};

export default NFTsIntro;
