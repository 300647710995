import React from 'react';
import CardBg01 from '../../../images/world/biomes/Biomes_card_bg_01.jpg';
import CardBg02 from '../../../images/world/biomes/Biomes_card_bg_02.jpg';
import CardBg03 from '../../../images/world/biomes/Biomes_card_bg_03.jpg';
import CardBg04 from '../../../images/world/biomes/Biomes_card_bg_04.jpg';
import CardBg05 from '../../../images/world/biomes/Biomes_card_bg_05.jpg';
import CardBg06 from '../../../images/world/biomes/Biomes_card_bg_06.jpg';
import bgImg from '../../../images/WingBg03.jpg';
import bgImgSm from '../../../images/WingBg03-sm.jpg';
import CardsSection from '../CardsSection';
import { paths } from '../../../routes/paths';

const boxes = [
  {
    title: 'City scapes',
    subTitle: 'in the clouds',
    content: (
      <p>
        The main Divine Council and decision-making is bound to angelic cities. The most notable is the capital city
        with its Crystal palace, where Arcania resides.
      </p>
    ),
    img: CardBg01,
    decorations: { bottomRight: true, topLeft: true }
  },
  {
    title: 'Rural Areas',
    subTitle: 'peaceful village life',
    content: (
      <p>
        Although the main hustle and bustle takes place in the angelic cities, the rural areas provide shelter for
        other, more subtle affairs.
      </p>
    ),
    img: CardBg02,
    decorations: { bottomRight: true, topLeft: true }
  },
  {
    title: 'Magical Forests',
    subTitle: 'Element of Nature',
    content: (
      <p>
        Naturally, many thriving exotic forests, filled with mystical animals, occupy vast areas of the angelic world.
      </p>
    ),
    img: CardBg03,
    decorations: { bottomRight: true, topLeft: true }
  },
  {
    title: 'Volcanic realm',
    subTitle: 'Lava everywhere',
    content: (
      <p>Several element types exist in the angelic world. One of them is fire, represented by volcanic biome.</p>
    ),
    img: CardBg04,
    decorations: { bottomLeft: true, topRight: true }
  },
  {
    title: 'Sea Shores',
    subTitle: 'with singing dolphins',
    content: <p>Water - another important element, providing life to multitude of thriving beings.</p>,
    img: CardBg05,
    decorations: { bottomLeft: true, topRight: true }
  },
  {
    title: 'Hidden Places',
    subTitle: 'with mysthical treasures',
    content: <p>Ideal places for quests and adventures, filled with both danger and precious rewards.</p>,
    img: CardBg06,
    decorations: { bottomLeft: true, topRight: true }
  }
];

const WorldBiomes = () => (
  <CardsSection
    id="biomes"
    linkTo={`${paths.world}/#story`}
    linkText="Story"
    boxes={boxes}
    title="Biomes"
    bgImg={bgImg}
    bgImgSm={bgImgSm}
  />
);

export default WorldBiomes;
