import React from 'react';
import AnimatedAngel from './AnimatedAngel';
import AngelBody from '../../images/movingAngels/Ehrya/Angel-Body.png';
import AngelAura from '../../images/movingAngels/Ehrya/Angel-Aura.png';
import AngelParticles from '../../images/movingAngels/Ehrya/Angel-Particles.png';
import AngelLeftAcc from '../../images/movingAngels/Ehrya/Angel-LeftAcc.png';
import AngelWing from '../../images/movingAngels/Ehrya/Angel-Wing.png';
import AngelFull from '../../images/movingAngels/Ehrya/Angel-Full.png';

const Angel = (props) => (
  <AnimatedAngel
    particles={AngelParticles}
    aura={AngelAura}
    wing={AngelWing}
    body={AngelBody}
    leftAccessory={AngelLeftAcc}
    fullImage={AngelFull}
    {...props}
  />
);

export default Angel;
