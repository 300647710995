export const externalLinks = {
  website: 'https://www.angelsofaether.xyz',
  twitter: 'https://twitter.com/angelsofaether',
  // discord: 'https://discord.gg/TtuKV4bWDx', // older one, but functional
  discord: 'https://discord.gg/vz76vyJzpf', // latest most precise
  instagram: 'https://www.instagram.com/angels.of.aether',
  youtube: 'https://www.youtube.com/channel/UCAQTj9C7gK7YF1x-mAvA4rQ',
  openSeaAoA: 'https://opensea.io/collection/angels-of-aether',
  openSeaGoA: 'https://opensea.io/collection/guardians-of-aether',
  mail: 'info@angelsofaether.com',
  mailTo: 'mailto:info@angelsofaether.com',
  mint: 'https://mint.angelsofaether.com/',
  twitterAmaliach: 'https://twitter.com/Amaliacht',
  twitterInfinite: 'https://twitter.com/infinite_stream',
  twitterBlue: 'https://twitter.com/BlueBirdNFT'
};
