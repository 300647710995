import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/AV_logo.png';
import { headerLinks } from '../../staticData/MenuItems';
import bgImg from '../../images/Menu_marble_bar_01.jpg';
import StyledHashLink from '../link/StyledHashLink';

const HeaderWrapper = styled.header`
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.showMobileMenu}) {
    display: none;
  }
`;

const HeaderContentWrapper = styled.div`
  width: 100%;
  height: 70px;
  margin: 0 auto;

  background-image: url(${bgImg});
  background-size: cover;
  background-position: center;

  border-bottom: 4px solid ${(props) => props.theme.color.gold};

  position: fixed;
  top: 0;
  z-index: 1000;

  display: flex;
  justify-content: space-between;
`;

const LinkGroup = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Logo = styled.img`
  height: 10vw;
  max-height: 140px;
`;

const SubLinksWrapper = styled.div`
  position: absolute;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-bottom: 3px solid ${(props) => props.theme.color.gold};
  top: 40px;
  padding-top: 30px;
  border-radius: 0 0 3px 0;
`;

const SubLinksInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.padding.half};
  flex-direction: column;
  box-sizing: border-box;
  background: rgba(1, 24, 37, 0.8);
  border-radius: 0 0 3px 0;
  border-top: 4px solid #d6c58b;
`;

export const HeaderLink = styled(StyledHashLink)`
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.color.footerLink};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 14px;
  }

  // for mobile menu
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 30px;
  }

  &:hover {
    color: ${(props) => props.theme.color.footerLinkHover};
    text-shadow: 0 0 5px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 10px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 20px ${(props) => props.theme.color.footerLinkHoverShadow},
      0 0 40px ${(props) => props.theme.color.footerLinkHoverShadow};
  }
`;

const SubLink = styled(HashLink)`
  font-family: ${(props) => props.theme.font.family.titles1};
  color: ${(props) => props.theme.color.subItem};
  text-decoration: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: ${(props) => props.theme.padding.tiny};

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 14px;
  }

  &:hover {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #efdcba;
  }
`;

const Header = () => {
  // divide array of links into two arrays
  const { leftLinks, rightLinks } = useMemo(() => {
    const indexToSplit = Math.round(headerLinks.length / 2);
    const first = headerLinks.slice(0, indexToSplit);
    const second = headerLinks.slice(indexToSplit);

    return { leftLinks: first, rightLinks: second };
  }, []);

  const [openedMenuItem, setOpenedMenuItem] = useState(false);

  return (
    <HeaderWrapper>
      <HeaderContentWrapper>
        <LinkGroup>
          {leftLinks.map((link, itemIndex) => (
            <div
              key={`header-item-${itemIndex}`}
              onMouseEnter={() => {
                if (link.links) {
                  setOpenedMenuItem(link.title);
                }
              }}
              onMouseLeave={() => {
                if (link.links) {
                  setOpenedMenuItem(null);
                }
              }}
            >
              <HeaderLink to={link.linkTo} key={`link-to-${link.linkTo}`}>
                {link.title}
              </HeaderLink>
              {link.links && openedMenuItem === link.title && (
                <SubLinksWrapper>
                  <SubLinksInnerWrapper>
                    {link.links.map((subLink) => (
                      <SubLink smooth key={`${link.title}-sublink-${subLink.title}`} to={subLink.linkTo}>
                        {subLink.title}
                      </SubLink>
                    ))}
                  </SubLinksInnerWrapper>
                </SubLinksWrapper>
              )}
            </div>
          ))}
        </LinkGroup>
        <Logo src={logo} />
        <LinkGroup>
          {rightLinks.map((link, itemIndex) => (
            <div
              key={`header-item-${itemIndex}`}
              onMouseEnter={() => {
                if (link.links) {
                  setOpenedMenuItem(link.title);
                }
              }}
              onMouseLeave={() => {
                if (link.links) {
                  setOpenedMenuItem(null);
                }
              }}
            >
              <HeaderLink to={link.linkTo} key={`link-to-${link.linkTo}`}>
                {link.title}
              </HeaderLink>
              {link.links && openedMenuItem === link.title && (
                <SubLinksWrapper>
                  <SubLinksInnerWrapper>
                    {link.links.map((subLink) => (
                      <SubLink smooth key={`${link.title}-sublink-${subLink.title}`} to={subLink.linkTo}>
                        {subLink.title}
                      </SubLink>
                    ))}
                  </SubLinksInnerWrapper>
                </SubLinksWrapper>
              )}
            </div>
          ))}
        </LinkGroup>
      </HeaderContentWrapper>
    </HeaderWrapper>
  );
};

export default Header;
