import React from 'react';
import { Controller } from 'react-scrollmagic';
import IntroSection from './sections/home/IntroSection';
import HomeExpansions from './sections/home/HomeExpansions';
import HomeGallery from './sections/home/HomeGallery';
import HomeSections from './sections/home/HomeSections';

const HomePage = () => {
  return (
    <Controller>
      <IntroSection />
      <HomeSections />
      <HomeExpansions />
      <HomeGallery />
    </Controller>
  );
};

export default HomePage;
