import React from 'react';
import CardBg01 from '../../../images/raceGame/mapLocations/MapLocation_card_bg_01.jpg';
import CardBg02 from '../../../images/raceGame/mapLocations/MapLocation_card_bg_02.jpg';
import CardBg03 from '../../../images/raceGame/mapLocations/MapLocation_card_bg_03.jpg';
import CardBg04 from '../../../images/raceGame/mapLocations/MapLocation_card_bg_04.jpg';
import CardBg05 from '../../../images/raceGame/mapLocations/MapLocation_card_bg_05.jpg';
import CardBg06 from '../../../images/raceGame/mapLocations/MapLocation_card_bg_06.jpg';
import bgImg from '../../../images/raceGame/mapLocations/AoA_Map_BG.jpg';
import bgImgSm from '../../../images/raceGame/mapLocations/AoA_Map_BG-sm.jpg';
import CardsSection from '../CardsSection';
import { paths } from '../../../routes/paths';

const boxes = [
  {
    title: 'Island Citadel',
    subTitle: 'where it all starts',
    content: (
      <p>The core of the race island is the citadel. It is also the starting point of the angelic flying race event.</p>
    ),
    img: CardBg01,
    decorations: { topLeft: true }
  },
  {
    title: 'Over the Sea',
    subTitle: 'whispering to whales',
    content: (
      <p>
        As the race starts, the early fly path goes above the sea shore, where beautiful water falls and hidden lagoons
        can be admired.
      </p>
    ),
    img: CardBg02,
    decorations: { topLeft: true }
  },
  {
    title: 'Sand Beach',
    subTitle: 'with crazy Koalas',
    content: (
      <p>
        The jungle behind the sand beach is occupied by a fierce tribe of warrior Koalas, who do not like some winged
        creatures flying over their heads.
      </p>
    ),
    img: CardBg03,
    decorations: { topLeft: true }
  },
  {
    title: 'Lava Path',
    subTitle: 'so hot',
    content: (
      <p>
        It is pretty obvious that this isle was created by volcanic activity. In addition to a huge volcano, the lava
        streams cover a significant part of the country.
      </p>
    ),
    img: CardBg04,
    decorations: { topLeft: true }
  },
  {
    title: 'Island Caverns',
    subTitle: 'claustrophobic',
    content: <p>Rich system of caves provides a great oportunity to test the acrobatics of any brave contender.</p>,
    img: CardBg05,
    decorations: { topLeft: true }
  },
  {
    title: 'The Vilage',
    subTitle: 'almost finish',
    content: (
      <p>
        The last stage of the race leads through a peaceful island vilage, where many skillful craftsmen offer their
        masterpieces.
      </p>
    ),
    img: CardBg06,
    decorations: { bottomRight: true, topLeft: true }
  }
];

const MapLocations = () => (
  <CardsSection
    id="locations"
    linkTo={`${paths.game}/#gallery`}
    linkText="Race Game Gallery"
    boxes={boxes}
    title="Important Locations"
    bgImg={bgImg}
    bgImgSm={bgImgSm}
  />
);

export default MapLocations;
